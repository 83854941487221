import {
  useEffect,
  memo,
} from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { AuthenticationResult } from '@azure/msal-browser';
import Loader from 'components/Loader';
import { AccessUnit } from 'components/Access/Access';
import PrivateRoute from 'components/PrivateRoute';
import ChooseOrganization from 'screens/Organizations/ChooseOrganization/ChooseOrganization';
import Dashboard from 'screens/Dashboard/Dashboard';
import Treasury from 'screens/Treasury/Treasury';
import MasterAccountTransactions from 'screens/Treasury/TreasuryMasterAccount/MasterAccountTransactions/MasterAccountTransactions';
import Administrators from 'screens/Administrators/Administrators';
import Organizations from 'screens/Organizations/Organizations';
import Employees from 'screens/Employees/Employees';
import WorkerLinking from 'screens/WorkerLinking/WorkerLinking';
import EmployeeDetails from 'screens/Employees/EmployeeDetails/EmployeeDetails';
import OrganizationForm from 'screens/Organizations/OrganizationForm/OrganizationForm';
import BatchForm from 'screens/Batches/BatchAdministration/EWABatchesSection/BatchForm/BatchForm';
import BatchManagement from 'screens/Batches/BatchManagement';
import PaymentAllocation from 'screens/Batches/BatchAdministration/PaymentAllocation/PaymentAllocation';
import Login from 'screens/Login';
import {
  userSelector,
  setUser,
} from 'store/slices/user';
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks';
import ErrorFallback from 'components/ErrorFallback';
import ToastNotification from 'components/ToastNotification';
import {
  setError,
  resetError,
} from 'store/slices/error';
import {
  loaderSelector,
  setLoader,
} from 'store/slices/loader';
import { languageSelector } from 'store/slices/application';
import MenuNavigation from 'components/MenuNavigation';
import 'i18n';
import 'App.scss';
import { loginRequest } from 'auth/authConfig';
import WorkerAccountTransactions from 'screens/Treasury/TreasuryWorkerAccounts/WorkerAccountTransactions/WorkerAccountTransactions';
import Workers from 'screens/Workers/Workers';
import Maintenance from 'screens/Maintenance';
import IntegrationData from 'screens/IntegrationData/IntegrationData';
import TipManagement from 'screens/TipManagement/TipManagement';
import TippedEmployeeTPOPaymentsPerLocationPerPeriod from 'screens/TipManagement/EmployeeTipOverviewTPOPayments/TippedEmployeeTPOPaymentsPerLocationPerPeriod';
import AdministratorForm from 'screens/Administrators/AdministratorForm/AdministratorForm';
import PaycardManagement from 'screens/PaycardManagement/PaycardManagement';
import PaycardManagementRegisterCard from 'screens/PaycardManagement/PaycardManagementRegisterCard/PaycardManagementRegisterCard';
import PaycardManagementPaycardsEmployeeManageFunds
  from 'screens/PaycardManagement/PaycardManagementPaycardSection/ManageFunds/PaycardManagementPaycardsEmployeeManageFunds';
import OrderNewCards from 'screens/PaycardManagement/PaycardManagementCardStockSection/OrderNewCards/OrderNewCards';
import LoadFunds from 'screens/PaycardManagement/PaycardManagementPaycardSection/ManageFunds/ManageFundsTable/LoadFunds/LoadFunds';
import DashAccountTransactions from 'screens/Treasury/TreasuryMasterAccount/DashAccountTransactions/DashAccountTransactions';

export type idTokenClaims = {
  name: string
}

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const language = useAppSelector(languageSelector);
  const loaderState = useAppSelector(loaderSelector);
  const { t, i18n } = useTranslation(['errors']);
  const user = useAppSelector(userSelector);
  const { accessToken } = user;
  const hasToken = !!accessToken && isAuthenticated;
  const acquireAccessToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    instance.acquireTokenSilent(request).then((response: AuthenticationResult) => {
      const { account } = response;
      dispatch(setLoader({ active: false }));
      dispatch(setUser({ name: account?.name, accessToken: response.accessToken, isAuthenticated: true }));
    })
      .catch((err) => {
        dispatch(setError({ message: err?.message || t('errors:somethingWentWrong'), errorTitle: err?.code || t('errors:error') }));
      });
  };

  useEffect(() => {
    isAuthenticated && acquireAccessToken();
  }, [isAuthenticated]);

  useEffect(() => {
    i18n.changeLanguage(language?.id || 'en');
  }, [language]);

  return (
    <>
      <Loader active={loaderState || inProgress !== 'none'} />
      <ToastNotification />
      <ErrorFallback />
      <ErrorBoundary
        fallbackRender={() => null}
        onReset={() => dispatch(resetError())}
        onError={(error) => dispatch(setError({ message: error?.message }))}
      >
        <Router>
          <MenuNavigation />
          <div className="content">
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <PrivateRoute hasToken={hasToken} path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute hasToken={hasToken} path="/maintenance">
                <Maintenance />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[
                  AccessUnit.TreasuryManager,
                  AccessUnit.TreasuryReader,
                  AccessUnit.FBOManager,
                  AccessUnit.FBOReader,
                ]}
                hasToken={hasToken}
                path="/treasury/transaction-history/master-account"
              >
                <MasterAccountTransactions />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[
                  AccessUnit.TreasuryManager,
                  AccessUnit.TreasuryReader,
                  AccessUnit.FBOManager,
                  AccessUnit.FBOReader,
                ]}
                hasToken={hasToken}
                path="/treasury/transaction-history/dash-account"
              >
                <DashAccountTransactions />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[
                  AccessUnit.TreasuryManager,
                  AccessUnit.TreasuryReader,
                  AccessUnit.FBOManager,
                  AccessUnit.FBOReader,
                ]}
                hasToken={hasToken}
                path="/treasury/transaction-history/:id"
              >
                <WorkerAccountTransactions />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[
                  AccessUnit.TreasuryManager,
                  AccessUnit.TreasuryReader,
                  AccessUnit.FBOManager,
                  AccessUnit.FBOReader,
                  AccessUnit.EWAReport,
                ]}
                hasToken={hasToken}
                exact
                path="/treasury/section/:sectionIndex"
              >
                <Treasury />
              </PrivateRoute>
              <PrivateRoute hasToken={hasToken} exact path="/choose-organization">
                <ChooseOrganization />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]} hasToken={hasToken} path="/administrators/create">
                <AdministratorForm />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]}
                hasToken={hasToken}
                path="/administrators"
              >
                <Administrators />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager]}
                hasToken={hasToken}
                path="/workers"
              >
                <Workers />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]}
                hasToken={hasToken}
                path="/loggedOrganization/employees/:employeeID"
              >
                <EmployeeDetails />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]}
                hasToken={hasToken}
                path="/loggedOrganization/employees"
              >
                <Employees />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager]}
                hasToken={hasToken}
                path="/loggedOrganization/worker-linking"
              >
                <WorkerLinking />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager]} hasToken={hasToken} exact path="/organizations/create">
                <OrganizationForm />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager]} hasToken={hasToken} path="/organizations/:organizationID">
                <OrganizationForm />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]} hasToken={hasToken} path="/batches/create">
                <BatchForm />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager]}
                hasToken={hasToken}
                path="/tips/:employeeID"
              >
                <TippedEmployeeTPOPaymentsPerLocationPerPeriod />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager, AccessUnit.EWAClientLocationManager]}
                hasToken={hasToken}
                path="/tips"
              >
                <TipManagement />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.PaycardClientManager]}
                hasToken={hasToken}
                path="/paycard/register"
              >
                <PaycardManagementRegisterCard />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.PaycardClientManager]}
                hasToken={hasToken}
                path="/paycard/manage-funds/history"
              >
                <PaycardManagementPaycardsEmployeeManageFunds />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.PaycardClientManager]}
                hasToken={hasToken}
                path="/paycard/manage-funds/load"
              >
                <LoadFunds />
              </PrivateRoute>
              <PrivateRoute
                oneOf={[AccessUnit.PaycardClientManager]}
                hasToken={hasToken}
                path="/paycard/card-stock/order-cards"
              >
                <OrderNewCards />
              </PrivateRoute>
              <PrivateRoute
                combination={[
                  [AccessUnit.PaycardClientManager, AccessUnit.EWAClientManager],
                  [AccessUnit.EWAManager, AccessUnit.PaycardReader],
                  [AccessUnit.EWAClientManager, AccessUnit.PaycardReader],
                ]}
                hasToken={hasToken}
                path="/paycard"
              >
                <PaycardManagement />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager]} hasToken={hasToken} exact path="/repayments/:repaymentID">
                <PaymentAllocation />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]} hasToken={hasToken} exact path="/batches/section/:sectionIndex">
                <BatchManagement />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.TipsReader]} hasToken={hasToken} exact path="/integrationData">
                <IntegrationData />
              </PrivateRoute>
              <PrivateRoute oneOf={[AccessUnit.EWAManager, AccessUnit.EWAClientManager]} hasToken={hasToken} exact path="/organizations">
                <Organizations />
              </PrivateRoute>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </div>
        </Router>
      </ErrorBoundary>
    </>
  );
};

export default memo(App);
