import React, { memo, useEffect, useState } from 'react';
import { TimePicker } from 'baseui/timepicker';
import { Button, KIND, SIZE } from 'baseui/button';
import { Block } from 'baseui/block';
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import { Banner, HIERARCHY, KIND as bannerKind } from 'baseui/banner';
import { useTranslation } from 'react-i18next';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import AppModal from 'components/AppModal/AppModal';
import { useAppDispatch } from 'store/hooks';
import { ModalNames, setModal } from 'store/slices/modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import AppFormControl from 'components/Form/AppFormControl';
import { bannerListStyles, bannerStyles } from '../../OrganizationFormHelpers';
import { createDateWithTime } from '../../../../../dataMappers/organizationsDataMappers';

const MAX_TIMES = 3;

interface Props {
  dailyPayoutTimes: string[];
  setDailyPayoutTimes: (value: string) => void;
  payrollCutoffTime?: Date | undefined;
  isOrgSettings?: boolean;
}

const OrganizationFormEditPayoutTimesModal = ({
  dailyPayoutTimes,
  setDailyPayoutTimes,
  payrollCutoffTime,
  isOrgSettings,
}: Props) => {
  const { t } = useTranslation(['organizations', 'errors', 'common', 'dateFormats']);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [times, setTimes] = useState<{ [key: string]: Date | null }>({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [initialValues, setInitialValues] = useState<{ [key: string]: Date | null }>({});
  const timeFormat = t('dateFormats:standard-time');

  useEffect(() => {
    const initialTimes = dailyPayoutTimes.reduce((acc, time, index) => {
      acc[`time${index}`] = time ? createDateWithTime(time) : null;
      return acc;
    }, {} as { [key: string]: Date | null });
    setTimes(initialTimes);
    setInitialValues(initialTimes);
  }, [dailyPayoutTimes]);

  const addNewTimeField = () => {
    if (Object.keys(times).length < MAX_TIMES) {
      const newKey = `time${Object.keys(times).length}`;
      setTimes((prevTimes) => ({ ...prevTimes, [newKey]: null }));
      setIsFormChanged(true);
    }
  };

  const removeTimeField = (key: string) => {
    setTimes((prevTimes) => {
      const updatedTimes = { ...prevTimes };
      delete updatedTimes[key];

      // Reorder the times
      const reorderedTimes = Object.keys(updatedTimes)
        .sort((a, b) => parseInt(a.replace('time', ''), 10) - parseInt(b.replace('time', ''), 10))
        .reduce((acc, curr, index) => {
          acc[`time${index}`] = updatedTimes[curr];
          return acc;
        }, {} as { [key: string]: Date | null });

      return reorderedTimes;
    });
    setIsFormChanged(true);
  };

  const handleTimeChange = (key: string, value: Date | null) => {
    setTimes((prevTimes) => ({ ...prevTimes, [key]: value }));
    setIsFormChanged(true);
  };

  const handleSubmitExtended = () => {
    const result = Object.values(times)
      .map((time) => moment(time).format('HH:mm:ss'))
      .join(', ');

    setDailyPayoutTimes(result);
    dispatch(setModal({ name: ModalNames.EDIT_PAYOUT_TIMES_MODAL, isOpen: false }));
  };

  const validateTimes = () => {
    const newErrors: { [key: string]: string } = {};
    Object.keys(times).forEach((key, index) => {
      const currentTime = times[key];
      const previousTime = index > 0 ? times[`time${index - 1}`] : null;

      if (!currentTime) {
        return;
      }

      const isAfterPreviousTime = previousTime ? moment(currentTime).isAfter(moment(previousTime)) : true;
      const is30MinutesApart = previousTime ? moment(currentTime).diff(moment(previousTime), 'minutes') >= 30 : true;

      if (!isAfterPreviousTime || !is30MinutesApart) {
        if (key === 'time1') {
          newErrors[key] = t('errors:error30MinutesApartAfterFirst');
        } else {
          newErrors[key] = t('errors:error30MinutesApartAfterSecond');
        }
      } else if (payrollCutoffTime) {
        const cuttOffTime = new Date(payrollCutoffTime);
        const isAfterCutoff = moment(currentTime).isSameOrAfter(cuttOffTime);
        if (isAfterCutoff) {
          newErrors[key] = t('errors:errorAfterCutoff');
        }
      }
    });
    return newErrors;
  };

  useEffect(() => {
    const newErrors = validateTimes();
    setError(newErrors);
  }, [times, payrollCutoffTime]);

  const isSaveDisabled = JSON.stringify(times) === JSON.stringify(initialValues)
  || Object.keys(times).length === 0 || Object.values(times).some((value) => value === null);

  return (
    <Layer index={400}>
      <AppModal
        modal={ModalNames.EDIT_PAYOUT_TIMES_MODAL}
        title={t('organizations:editPayoutTimesModal.title')}
        cancelBtnText={t('common:cancel')}
        actionBtnText={isOrgSettings ? t('common:edit') : t('common:save')}
        onAction={handleSubmitExtended}
        modalWidth={['360px', '360px', '550px', '550px']}
        minWidth="360px"
        maxWidth="100%"
        isActionDisabled={isSaveDisabled || !isFormChanged || (error && Object.keys(error).length > 0)}
      >
        <Block>
          <Grid
            gridColumns={12}
            gridMargins={16}
            gridGaps={16}
          >
            <Cell span={12}>
              <Banner
                kind={bannerKind.info}
                hierarchy={HIERARCHY.low}
                overrides={bannerStyles}
              >
                <LabelMedium>
                  {t('organizations:editPayoutTimesModal.banner.title')}
                </LabelMedium>
                <ul style={bannerListStyles}>
                  <li>
                    <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                      <b>{t('organizations:editPayoutTimesModal.banner.employeePayoutFees')}</b>
                      {t('organizations:editPayoutTimesModal.banner.employeePayoutFeesInfo')}
                    </ParagraphSmall>
                  </li>
                  <li>
                    <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                      <b>{t('organizations:editPayoutTimesModal.banner.limit')}</b>
                      {t('organizations:editPayoutTimesModal.banner.limitInfo')}
                    </ParagraphSmall>
                  </li>
                  <li>
                    <ParagraphSmall margin="0" style={{ whiteSpace: 'normal' }}>
                      <b>{t('organizations:editPayoutTimesModal.banner.earliestPayout')}</b>
                      {payrollCutoffTime
                        ? t(
                          'organizations:editPayoutTimesModal.banner.earliestPayoutInfo',
                          { earliestPayout: moment(payrollCutoffTime).format(timeFormat) },
                        )
                        : t('organizations:editPayoutTimesModal.banner.earliestPayoutInfoOrgSettings')}
                    </ParagraphSmall>
                  </li>
                </ul>
              </Banner>
            </Cell>
          </Grid>
          <Grid align={ALIGNMENT.start} gridColumns={12} gridMargins={16} gridGaps={16}>
            {Object.keys(times).map((key, index) => {
              const previousTime = index > 0 ? times[`time${index - 1}`] : null;
              const errorMessage = error[key];

              return (
                <Cell
                  key={key}
                  span={12}
                  align={ALIGNMENT.start}
                  overrides={{
                    Cell: {
                      style: {
                        marginBottom: '0 !important',
                      },
                    },
                  }}
                >
                  <Block display="flex" alignItems="center" justifyContent="space-between" marginLeft="-16px" position="relative">
                    <Cell span={[8]} align={ALIGNMENT.start} gridGaps={0}>
                      <AppFormControl
                        error={errorMessage}
                        label={`${t('organizations:editPayoutTimesModal.payoutTime')} ${index + 1}`}
                        controlContainerStyle={{
                          marginBottom: '0 !important',
                        }}
                        controlLabelContainerStyle={{
                          marginTop: '0 !important',
                        }}
                      >
                        <TimePicker
                          size={SIZE.compact}
                          error={!!errorMessage}
                          creatable
                          nullable
                          format="12"
                          value={times[key]}
                          onChange={(date) => handleTimeChange(key, date)}
                          step={900}
                          placeholder={t('organizations:editPayoutTimesModal.selectTime')}
                          minTime={previousTime ? moment(previousTime).add(30, 'minutes').toDate() : undefined}
                        />
                      </AppFormControl>
                    </Cell>
                    <Button
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            position: 'absolute',
                            top: '28px',
                            right: '0',
                          }),
                        },
                      }}
                      type="button"
                      kind={KIND.tertiary}
                      size={SIZE.compact}
                      onClick={() => removeTimeField(key)}
                      disabled={Object.keys(times).length <= 1}
                    >
                      {t('common:remove')}
                    </Button>
                  </Block>

                </Cell>
              );
            })}
            <Cell span={12} align={ALIGNMENT.start}>
              <Block
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                flexDirection="column"
                gridGap="8px"
                marginTop="8px"
              >
                <Button
                  size={SIZE.compact}
                  type="button"
                  kind={KIND.secondary}
                  onClick={addNewTimeField}
                  disabled={Object.keys(times).length >= MAX_TIMES}
                >
                  <Block marginRight="8px">
                    <FontAwesomeIcon icon={faPlus} />
                  </Block>
                  {t('organizations:editPayoutTimesModal.addPayoutTime')}
                </Button>
              </Block>
            </Cell>
          </Grid>
        </Block>
      </AppModal>
    </Layer>
  );
};

export default memo(OrganizationFormEditPayoutTimesModal);
