import CommonHeader from 'components/CommonHeader/CommonHeader';
import {
  memo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  fetchOrganizationMasterAccountPaycardBalance,
  paycardMasterAccountBalanceFetchFailedSelector,
  paycardMasterAccountBalancePendingSelector,
  paycardMasterAccountBalanceSelector,
  resetEmployeeSearchAndCriteria,
  resetOrganizationsPaycardRegisterCardEmployeeDetails,
  resetOrganizationsPaycardRegisterCardLocation,
  resetOrganizationsPaycardRegisterCardPaymentDetails,
  resetOrganizationsPaycardRegisterCardSelectedEmployee,
} from 'store/slices/paycards';
import { useStyletron } from 'baseui';
import {
  HeadingXLarge,
  HeadingXSmall,
} from 'baseui/typography';
import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import { Skeleton } from 'baseui/skeleton';
import PriceFormatter from 'utils/priceFormatter';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import { useHistory } from 'react-router-dom';
import CellFormControl from 'components/CellFormControl';
import { resetWizardActiveSections } from 'store/slices/sections';
import {
  prevPageSelector,
  setPrevPage,
} from 'store/slices/application';
import { Block } from 'baseui/block';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { modalsSelector, ModalNames, setModal } from 'store/slices/modals';
import hasAccess from 'utils/hasAccess';
import { AccessCheckType, AccessUnit } from 'components/Access/Access';
import { buttonsContainerStyles, containerStyles } from './PaycardManagementHelper';
import PaycardManagementCardStockSection from './PaycardManagementCardStockSection/PaycardManagementCardStockSection';
import PaycardManagementPaycardsSection from './PaycardManagementPaycardSection/PaycardManagementPaycardsSection';
import PaycardManagementHelpAndSettingsModal from './PaycardManagementHelpAndSettingsModal/PaycardManagementHelpAndSettingsModal';

const PaycardManagement = () => {
  const dispatch = useAppDispatch();
  const balance = useAppSelector(paycardMasterAccountBalanceSelector);
  const pendingBalance = useAppSelector(paycardMasterAccountBalancePendingSelector);
  const fetchFailed = useAppSelector(paycardMasterAccountBalanceFetchFailedSelector);
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const [css] = useStyletron();
  const loggedOrganization = useAppSelector(loggedOrganizationSelector);
  const history = useHistory();
  const prevPage = useAppSelector(prevPageSelector);
  const modals = useAppSelector(modalsSelector);

  useEffect(() => {
    dispatch(fetchOrganizationMasterAccountPaycardBalance({ organizationId: loggedOrganization?.id }));
  }, []);

  const renderBalance = () => {
    if (pendingBalance) {
      return <Skeleton height="44px" width="200px" animation />;
    }
    if (fetchFailed) {
      return t('paycardManagement:paycardManagement.total.unableToLoadError');
    }
    return PriceFormatter().format(balance.availableBalance);
  };

  const handleRegisterCardClick = () => {
    history.push('/paycard/register');
  };

  const handleHelpClick = () => {
    dispatch(setModal({
      name: ModalNames.PAYCARD_HELP_AND_SETTINGS,
      isOpen: true,
    }));
  };

  useEffect(() => {
    if (!prevPage.startsWith('/paycards/register')) {
      dispatch(resetWizardActiveSections());
      dispatch(resetOrganizationsPaycardRegisterCardLocation());
      dispatch(resetOrganizationsPaycardRegisterCardSelectedEmployee());
      dispatch(resetOrganizationsPaycardRegisterCardEmployeeDetails());
      dispatch(resetOrganizationsPaycardRegisterCardPaymentDetails());
      dispatch(resetEmployeeSearchAndCriteria());
    }
    dispatch(setPrevPage(''));
  }, []);
  return (
    <>
      <div className={css(containerStyles)}>
        <CommonHeader title={t('paycardManagement:paycardManagement.pageHeader')} />
        <Grid
          align={ALIGNMENT.end}
          gridColumns={12}
        >
          <CellFormControl
            cellSpan={[12, 7, 4]}
            cellAlign={ALIGNMENT.end}
          >
            <HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.total.label')}</HeadingXSmall>
          </CellFormControl>
          <Cell skip={[0, 0, 4]} />
          <CellFormControl
            cellSpan={[12, 4, 3]}
            cellAlign={ALIGNMENT.end}
            formControlProps={{
              overrides: {
                ControlContainer: {
                  style: buttonsContainerStyles,
                },
              },
            }}
          >
            <Block
              display="flex"
              gridGap="16px"
            >
              <Button
                kind={KIND.secondary}
                onClick={handleHelpClick}
                size={SIZE.compact}
              >
                {t('paycardManagement:paycardManagement.registerCard.helpButton')}
              </Button>
              <Button
                kind={KIND.primary}
                onClick={handleRegisterCardClick}
                size={SIZE.compact}
                disabled={hasAccess(AccessCheckType.oneOf, [AccessUnit.PaycardReader])}
              >
                {t('paycardManagement:paycardManagement.registerCard.button')}
              </Button>
            </Block>
          </CellFormControl>
          <Cell
            span={12}
            align={ALIGNMENT.start}
          >
            <HeadingXLarge marginTop="8px">
              {renderBalance()}
            </HeadingXLarge>
          </Cell>
        </Grid>
        <Grid
          align={ALIGNMENT.start}
          gridMargins={[20, 50, 80]}
        >
          <PaycardManagementCardStockSection />
        </Grid>
        <br />
        <Grid
          align={ALIGNMENT.start}
          gridMargins={[20, 50, 80]}
        >
          <PaycardManagementPaycardsSection />
        </Grid>
      </div>
      {checkIsModalOpen(modals, ModalNames.PAYCARD_HELP_AND_SETTINGS) && <PaycardManagementHelpAndSettingsModal />}
    </>
  );
};

export default memo(PaycardManagement);
