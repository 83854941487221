import {
  SetWorkerProfileAttributesTrustedRequest,
  UpdateWorkerEmailValuesType,
  VerificationCodeTypeRequest,
  WorkerIDType,
} from 'types/WorkerTypes';
import request from './request';

export const setWorkerProfileAttributesTrustedRequest = (
  token: string,
  workerID: WorkerIDType,
  data: SetWorkerProfileAttributesTrustedRequest,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${workerID}/attributes/trusted`,
});

export const requestVerificationCodeRequest = (
  token: string,
  workerID: WorkerIDType,
  data: VerificationCodeTypeRequest,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${workerID}/contact/verify`,
});

export const updateWorkerEmailRequest = (
  token: string,
  workerID: WorkerIDType,
  data: UpdateWorkerEmailValuesType,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `${workerID}/email`,
});

export const other = null;
