import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchWorkerRequest,
} from 'api/employmentsAPI';
import {
  fetchWorkerEmploymentsRequest, fetchWorkersRequest,
} from 'api/workersAPI';
import {
  Worker,
  FetchWorkerParamsType,
  FetchWorkersParamsType,
  SetWorkerProfileAttributesTrustedParamsType,
  RequestVerificationCodeParamsType,
  UpdateWorkerEmailParamsType,
} from 'types/WorkerTypes';
import {
  resetLinkEmploymentsFormEvent,
} from 'store/events';
import { EmploymentType } from 'types/EmployeeTypes';
import {
  requestVerificationCodeRequest,
  setWorkerProfileAttributesTrustedRequest,
  updateWorkerEmailRequest,
} from 'workerProfileApi/workerProfileAttributes';
import { RootState } from '..';

export const initialState = {
  worker: null as Worker | null,
  pending: false,
  nextToken: '' as string,
  list: [] as Worker[],
  pendingList: false,
  areFetched: false,
  areSearched: false,
  workerEmployments: [] as EmploymentType[] | null,
  pendingEmployments: false,
  filter: {
    firstName: '',
    lastName: '',
    email: '',
    workerId: '',
  },
  filterCriteria: [{ id: 'name', name: '' }],
  workerProfileAttributesTrustedPending: false,
};

export const fetchWorker = createAsyncThunk(
  'workers/fetchWorker',
  async (params: FetchWorkerParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { workerID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchWorkerRequest(storeState.user.accessToken, workerID);
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);

export const fetchWorkerEmployments = createAsyncThunk(
  'workers/fetchWorkerEmployments',
  async (params: FetchWorkerParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { workerID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchWorkerEmploymentsRequest(storeState.user.accessToken, workerID);
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);
export const fetchWorkers = createAsyncThunk(
  'workers/fetchWorkers',
  async (params: FetchWorkersParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { filter } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchWorkersRequest(storeState.user.accessToken, filter);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const searchWorkers = createAsyncThunk(
  'workers/searchWorkers',
  async (params: FetchWorkersParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { filter } = params;
    const storeState = getState() as RootState;
    try {
      const result = await fetchWorkersRequest(storeState.user.accessToken, filter);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const setWorkerProfileAttributesTrusted = createAsyncThunk(
  'worker/profile/attributes/trusted',
  async (params: SetWorkerProfileAttributesTrustedParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { workerID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await setWorkerProfileAttributesTrustedRequest(storeState.user.accessToken, workerID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const requestVerificationCode = createAsyncThunk(
  'worker/details/verificationCode',
  async (params: RequestVerificationCodeParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { workerID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await requestVerificationCodeRequest(storeState.user.accessToken, workerID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateWorkerEmail = createAsyncThunk(
  'worker/details/email',
  async (params: UpdateWorkerEmailParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { workerID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await updateWorkerEmailRequest(storeState.user.accessToken, workerID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    resetWorker: (state) => {
      state.worker = initialState.worker;
    },
    setWorker: (state, action) => {
      state.worker = action.payload;
    },
    resetWorkers: (state) => {
      state.list = initialState.list;
      state.nextToken = initialState.nextToken;
      state.areSearched = initialState.areSearched;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setFilterFirstName: (state, action) => {
      state.filter.firstName = action.payload;
    },
    setFilterLastName: (state, action) => {
      state.filter.lastName = action.payload;
    },
    setFilterEmail: (state, action) => {
      state.filter.email = action.payload;
    },
    setFilterWorkerId: (state, action) => {
      state.filter.workerId = action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    setFilterCriteria: (state, action) => {
      state.filterCriteria = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorker.pending, (state) => {
      state.worker = initialState.worker;
      state.pending = true;
    });

    builder.addCase(fetchWorker.fulfilled, (state, action) => {
      state.worker = action.payload;
      state.pending = false;
    });

    builder.addCase(fetchWorker.rejected, (state) => {
      state.pending = false;
    });

    builder.addCase(fetchWorkerEmployments.pending, (state) => {
      state.workerEmployments = initialState.workerEmployments;
      state.pendingEmployments = true;
    });

    builder.addCase(fetchWorkerEmployments.fulfilled, (state, action) => {
      state.workerEmployments = action.payload.values;
      state.pendingEmployments = false;
    });

    builder.addCase(fetchWorkerEmployments.rejected, (state) => {
      state.pendingEmployments = false;
    });
    builder.addCase(fetchWorkers.pending, (state) => {
      state.pendingList = true;
    });

    builder.addCase(fetchWorkers.fulfilled, (state, action) => {
      state.list = state.list.concat(action.payload.values);
      state.nextToken = action.payload.nextToken;
      state.pendingList = false;
      state.areFetched = true;
    });

    builder.addCase(fetchWorkers.rejected, (state) => {
      state.pendingList = false;
    });
    builder.addCase(searchWorkers.pending, (state) => {
      state.pendingList = true;
      state.list = initialState.list;
    });

    builder.addCase(searchWorkers.fulfilled, (state, action) => {
      state.list = action.payload.values;
      state.areSearched = true;
      state.nextToken = '';
      state.pendingList = false;
    });

    builder.addCase(searchWorkers.rejected, (state) => {
      state.pendingList = false;
      state.nextToken = '';
    });
    builder.addCase(resetLinkEmploymentsFormEvent, (state) => {
      state.worker = initialState.worker;
    });

    builder.addCase(setWorkerProfileAttributesTrusted.pending, (state) => {
      state.workerProfileAttributesTrustedPending = true;
    });

    builder.addCase(setWorkerProfileAttributesTrusted.fulfilled, (state) => {
      state.workerProfileAttributesTrustedPending = false;
    });

    builder.addCase(setWorkerProfileAttributesTrusted.rejected, (state) => {
      state.workerProfileAttributesTrustedPending = false;
    });
  },
});

export const {
  resetWorker,
  resetWorkers,
  setFilter,
  resetFilter,
  setFilterCriteria,
  setFilterFirstName,
  setFilterLastName,
  setFilterEmail,
  setFilterWorkerId,
  setWorker,
} = workersSlice.actions;

export const workerSelector = (state: RootState): Worker | null | undefined => state.workers.worker;
export const workerPendingSelector = (state: RootState) => state.workers.pending;
export const workersPendingEmploymentsSelector = (state: RootState) => state.workers.pendingEmployments;
export const workersWorkerEmploymentsSelector = (state: RootState) => state.workers.workerEmployments;
export const workersFilterSelector = (state: RootState) => state.workers.filter;
export const workersFilterCriteriaSelector = (state: RootState) => state.workers.filterCriteria;
export const workersAreFetchedSelector = (state: RootState): boolean => state.workers.areFetched;
export const workersAreSearchedSelector = (state: RootState): boolean => state.workers.areSearched;
export const workersListSelector = (state: RootState): Worker[] => state.workers.list;
export const workersNextTokenSelector = (state: RootState) => state.workers.nextToken;
export const workersPendingListSelector = (state: RootState): boolean => state.workers.pendingList;
export const workerProfileAttributesTrustedPendingSelector = (state: RootState): boolean => state.workers.workerProfileAttributesTrustedPending;

export default workersSlice.reducer;
