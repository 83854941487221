import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchBatch, batchSelector } from 'store/slices/batches';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BatchIDType, BatchItem } from 'types/BatchTypes';
import PriceFormatter from 'utils/priceFormatter';
import DownloadFile from 'utils/downloadFile';
import { unScalePrice } from 'utils/priceScale';
import AppChip from 'components/AppChip/AppChip';
import HighLightSearch from 'components/HighLightSearch';
import Access, { AccessUnit } from 'components/Access/Access';
import {
  listStyles,
  listItemStyles,
  customerListItemNameAndStatusesStyles,
  statusBadgeStyles,
  detailsStyles,
  listItemInfoStyles,
  customerListItemTitleStyles,
  statusAndMenuStyle,
  exportIconStyle,
} from '../../../BatchesHelpers';

export type BatchesListItemPropsType = {
  batch: BatchItem,
  search?: string
}

const BatchesListItem = ({
  batch,
  search,
}: BatchesListItemPropsType) => {
  const { t } = useTranslation(['common', 'employees', 'organizations', 'batches', 'payGroups', 'dateFormats']);
  const dispatch = useAppDispatch();
  const batchState = useAppSelector(batchSelector);
  const { csv, id } = batchState || {};
  const [css] = useStyletron();
  const [selectedBatch, setSelectedBatch] = useState<BatchIDType>(undefined);

  const dateFormat = t('dateFormats:standard');

  const handleMenuItemClick = ({
    batchID,
  }: {
    batchID: BatchIDType,
  }) => {
    dispatch(fetchBatch({ batchID }));
    setSelectedBatch(batchID);
  };

  useEffect(() => {
    if (id === selectedBatch && csv) {
      DownloadFile(
        `${selectedBatch}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`,
      );
      setSelectedBatch(undefined);
    }
  }, [id, selectedBatch]);

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(customerListItemNameAndStatusesStyles)}>
          <div className={css(customerListItemTitleStyles)}>
            <HighLightSearch search={search}>
              {batch?.batchReference}
            </HighLightSearch>
          </div>

          <div className={css(statusAndMenuStyle)}>
            {batch?.includeInFileDeductionCount > 0 ? (
              <>
                <span className={css({
                  ...statusBadgeStyles(batch.status),
                  borderTopRightRadius: '4px',
                  borderTopLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                  borderBottomLeftRadius: '4px',
                  marginRight: '8px',
                })}
                >
                  <HighLightSearch search={search}>
                    {t(`batches:statuses.${batch?.status}`)}
                  </HighLightSearch>
                </span>
                <Access
                  oneOf={[AccessUnit.EWAClientManager, AccessUnit.EWAManager]}
                >
                  <FontAwesomeIcon
                    title={t('batches:exportToCSV')}
                    size="lg"
                    className={css(exportIconStyle)}
                    icon={faFileCsv}
                    onClick={() => handleMenuItemClick({ batchID: batch?.id.toString() })}
                  />
                </Access>
              </>
            ) : (
              <span className={css({
                ...statusBadgeStyles(batch.status),
                borderTopRightRadius: '4px',
                borderTopLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                marginRight: '44px',
              })}
              >
                <HighLightSearch search={search}>
                  {t(`batches:statuses.${batch?.status}`)}
                </HighLightSearch>
              </span>
            )}
          </div>
        </div>

        <div className={css(listItemInfoStyles)}>
          <div>
            <span className={css(detailsStyles)}>
              {t('common:payPeriod')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {`${moment(batch?.payrollPeriodStartDate).format(dateFormat)} - ${moment(batch?.payrollPeriodEndDate).format(dateFormat)}`}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('payGroups:payGroupsCycle')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.payGroupPayFrequencyRule}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('batches:creationDate.label')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {moment(batch?.creationDate).format(dateFormat)}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('employees:workers')}
              :
              <AppChip
                items={(
                  <span>
                    {batch?.workersCount}
                  </span>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('batches:amount')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.amount && PriceFormatter().format(unScalePrice(batch?.amount?.value, batch?.amount?.scale))}
                  </HighLightSearch>
                )}
              />
            </span>
            <span className={css(detailsStyles)}>
              {t('batches:generated')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.creationTrigger?.replace('ADMIN_API', t('batches:manuallyGenerated')?.replace('SCHEDULER', t('batches:autoGenerated')))}
                  </HighLightSearch>
                )}
              />
            </span>
            {batch?.status === 'PARTIALLY_PAID'
              && (
                <span className={css(detailsStyles)}>
                  {t('batches:amountDue')}
                  :
                  <AppChip
                    items={(
                      <HighLightSearch search={search}>
                        {PriceFormatter().format(unScalePrice(batch?.amountDue.value, batch?.amountDue.scale))}
                      </HighLightSearch>
                    )}
                  />
                </span>
              )}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default BatchesListItem;
