import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchAllOrganizationsRequest,
  createOrganizationRequest,
  editOrganizationRequest,
  fetchOrganizationByIdRequest,
  fetchOrganizationConfigsRequest,
  editOrganizationConfigsRequest,
  fetchOrganizationSectionsStatusesRequest,
  editOrganizationSectionStatusesRequest,
  createOrganizationSectionStatusesRequest,
} from 'api/organizationsAPI';
import { fetchDefaultConfigRequest } from 'api/api';
import {
  SaveOrganizationParamsType,
  EditOrganizationEWASettingsParamsType,
  OrganizationConfigResponseType,
  DefaultConfigResponseType,
  OrganizationSectionStatusesResponseType,
  EditOrganizationSectionStatusesParamsType,
  CreateOrganizationSectionStatusesParamsType,
  FetchAllOrganizationsParamsType,
  OrganizationStatuses,
  EditOrganizationTPOSettingsParamsType,
  OrganizationDirectDepositBankingConfigResponseType,
  EditOrganizationTipSourceConfigurationParamsType,
} from 'types/OrganizationTypes';
import {
  FetchLocationsParamsType,
} from 'types/LocationTypes';
import {
  resetOrganizationEvent,
} from 'store/events';
import { AccessUnit } from 'components/Access/Access';
import {
  SetBankingOrganizationTPOSettingsParamsType,
} from 'types/TPOTypes';
import { setOrganizationTPOSettingsRequest } from 'bankingApi/tpoSettingsAPI';
import { fetchOrganizationDirectDepositSettingsRequest, setOrganizationDirectDepositSettingsRequest } from 'directDepositApi/organizationSettings';
import {
  FetchOrganizationDirectDepositSettingsParamsType,
  FetchOrganizationPayrollSystemSettingsParamsTypes,
  FetchOrganizationPayrollSystemSettingsTypes,
  PayrolSystemsResponseType,
  SaveOrganizationPayrollSystemSettingsParamsTypes,
  SetOrganizationDirectDepositSettingsParamsType,
} from 'types/DirectDepositTypes';
import environment from '../../environment';
import { RootState } from '..';
import { fetchEmployeesWithConfigsLocationsAndGroups } from './employees';
import { setLoggedOrganization } from './loggedOrganization';
import {
  fetchOrganizationPayrollSystemSettingsRequest,
  fetchPayrollSystemsRequest,
  saveOrganizationPayrollSystemSettingsRequest,
} from '../../pspApi/payrolSystemApi';

export interface Organization {
  id: string
  name: string,
  externalId: string,
  deductionCode?: string,
  invitationCode?: string,
  sectionStatuses?: OrganizationSectionStatusesResponseType[],
}

export const initialState = {
  list: [] as Organization[],
  pending: false,
  pendingList: false,
  pendingConfigs: false,
  shouldGoIntoMaintenanceScreen: false,
  organization: undefined as Organization | undefined,
  defaultConfig: null as DefaultConfigResponseType | null,
  organizationConfig: null as OrganizationConfigResponseType[] | null,
  organizationTipSourceConfigSuccessfullySaved: false,
  sectionStatuses: [] as OrganizationSectionStatusesResponseType[],
  pageNumber: 1 as number,
  pageSize: 50 as number,
  totalSize: 0 as number,
  numPages: 1 as number,
  tab: OrganizationStatuses.ACTIVE,
  search: '' as string,
  tpoBankingSettings: {
    config: null,
    pendingTPOBankingConfigs: false,
  },
  organizationBankingConfig: null as OrganizationDirectDepositBankingConfigResponseType | null,
  pendingOrganizationBankingConfig: false,
  automaticBankDetailsUpdates: null as FetchOrganizationPayrollSystemSettingsTypes | null,
  pendingAutomaticBankDetailsUpdates: false,
  payrollSystems: [] as PayrolSystemsResponseType[],
  pendingPayrollSystems: false,
  rejectedPayrollSystems: false,
};

export const fetchAllOrganizations = createAsyncThunk(
  'organizations/fetchAll',
  async (
    params: FetchAllOrganizationsParamsType | undefined,
    { dispatch, getState, rejectWithValue },
  ): Promise<any> => {
    const storeState = getState() as RootState;
    const {
      status = '' as OrganizationStatuses,
      pageNumber = '1',
      pageSize = '50',
      namePattern = '',
    } = params || {};

    try {
      const result = await fetchAllOrganizationsRequest(storeState.user.accessToken, status, pageNumber, pageSize, namePattern);
      if (storeState.user.accessUnits.includes(AccessUnit.EWAClientManager)
      && !storeState.loggedOrganization.organization
      && storeState.user.organizationUnits.length === 1) {
        dispatch(setLoggedOrganization(result.values[0]));
      }
      if (storeState.user.accessUnits.includes(AccessUnit.TipsClientManager)) {
        dispatch(setLoggedOrganization(result.values[0]));
      }
      if (storeState.user.accessUnits.includes(AccessUnit.EWAClientLocationManager)) {
        dispatch(setLoggedOrganization(result.values[0]));
      }
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationByIdWithConfigAndSectionStatuses = createAsyncThunk(
  'organizations/fetchByIdWithConfigsAndStatuses',
  async (params: FetchLocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await Promise.all([
        fetchOrganizationByIdRequest(storeState.user.accessToken, organizationID),
        fetchOrganizationConfigsRequest(storeState.user.accessToken, organizationID),
        fetchOrganizationSectionsStatusesRequest(storeState.user.accessToken, organizationID),
      ]);
      let configs = result[1]?.values;
      const tempStorageValue = localStorage.getItem(`bank-details-${organizationID}-${environment.authorityDomain}`);
      if (tempStorageValue) {
        const localStorageValues = JSON.parse(tempStorageValue);
        configs = [...configs, ...localStorageValues];
      }
      return [
        result[0],
        configs,
        result[2]?.values,
      ];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationConfigs = createAsyncThunk(
  'organizations/organizationConfigs',
  async (params: FetchLocationsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchOrganizationConfigsRequest(storeState.user.accessToken, organizationID);
      const tempStorageValue = localStorage.getItem(`bank-details-${organizationID}-${environment.authorityDomain}`);
      if (tempStorageValue) {
        const localStorageValues = JSON.parse(tempStorageValue);
        if (result.values) return [...result.values, ...localStorageValues];
        return localStorageValues;
      }
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationConfigs = createAsyncThunk(
  'organizations/editConfigs',
  async (params: any, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, data);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createOrganizationSectionStatuses = createAsyncThunk(
  'organizations/sectionStatuses/create',
  async (params: CreateOrganizationSectionStatusesParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await createOrganizationSectionStatusesRequest(storeState.user.accessToken, organizationID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationSectionStatuses = createAsyncThunk(
  'organizations/sectionStatuses/edit',
  async (params: EditOrganizationSectionStatusesParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, sectionID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await editOrganizationSectionStatusesRequest(storeState.user.accessToken, organizationID, sectionID, data);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDefaultConfig = createAsyncThunk(
  'organizations/fetchDefaultConfig',
  async (_action, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      return await fetchDefaultConfigRequest(storeState.user.accessToken);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createOrganization = createAsyncThunk(
  'organizations/create',
  async (params: SaveOrganizationParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { data, configs } = params;
    const storeState = getState() as RootState;

    try {
      const organization = await createOrganizationRequest(storeState.user.accessToken, data);

      dispatch(editOrganizationConfigs({
        organizationID: organization?.id,
        data: configs,
      }));
      dispatch(setLoggedOrganization(organization));
      return organization;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganization = createAsyncThunk(
  'organizations/edit',
  async (params: SaveOrganizationParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data, configs } = params;
    const storeState = getState() as RootState;

    try {
      const result = await Promise.all([
        editOrganizationRequest(storeState.user.accessToken, organizationID, data),
        editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, configs),
      ]);
      return [result[0], result[1]?.values];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationEWASettings = createAsyncThunk(
  'organizations/EWASettings/edit',
  async (params: EditOrganizationEWASettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, configs } = params;
    const storeState = getState() as RootState;

    try {
      const result = await editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, configs);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationTPOSettings = createAsyncThunk(
  'organizations/TPOSettings/edit',
  async (params: EditOrganizationTPOSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, configs } = params;
    const storeState = getState() as RootState;
    try {
      const result = await editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, configs);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationDirectDepositSettings = createAsyncThunk(
  'organizations/DDSettings/edit',
  async (params: EditOrganizationTPOSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, configs } = params;
    const storeState = getState() as RootState;
    try {
      const result = await editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, configs);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationPayrollSettings = createAsyncThunk(
  'organizations/payrolSettings/edit',
  async (params: EditOrganizationTPOSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, configs } = params;
    const storeState = getState() as RootState;
    try {
      const result = await editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, configs);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveOrganizationTPOSettingsBanking = createAsyncThunk(
  'organization/banking/tpo/settings',
  async (params: SetBankingOrganizationTPOSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await setOrganizationTPOSettingsRequest(storeState.user.accessToken, organizationID, data);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveOrganizationDirectDepositSettingsBanking = createAsyncThunk(
  'organization/direct-deposit/saveSettings',
  async (params: SetOrganizationDirectDepositSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await setOrganizationDirectDepositSettingsRequest(storeState.user.accessToken, organizationID, data);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editOrganizationTipSourceConfiguration = createAsyncThunk(
  'organizations/TipSourceConfiguration/edit',
  async (params: EditOrganizationTipSourceConfigurationParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, configs } = params;
    const storeState = getState() as RootState;

    try {
      const result = await editOrganizationConfigsRequest(storeState.user.accessToken, organizationID, configs);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationDirectDepositSettingsBanking = createAsyncThunk(
  'organization/direct-deposit/fetchSettings',
  async (params: FetchOrganizationDirectDepositSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchOrganizationDirectDepositSettingsRequest(storeState.user.accessToken, organizationID);
      return result;
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);

export const fetchOrganizationPayrollSystemSettings = createAsyncThunk(
  'organization/configuration/fetchPayrollSystemSettings',
  async (params: FetchOrganizationPayrollSystemSettingsParamsTypes, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchOrganizationPayrollSystemSettingsRequest(storeState.user.accessToken, organizationID);
      return result;
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);

export const saveOrganizationPayrollSystemSettings = createAsyncThunk(
  'organization/configuration/savePayrollSystemSettings',
  async (params: SaveOrganizationPayrollSystemSettingsParamsTypes, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await saveOrganizationPayrollSystemSettingsRequest(storeState.user.accessToken, organizationID, data);
      return result;
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);

export const fetchPayrollSystems = createAsyncThunk(
  'payroll-systems',
  async (params: any, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      const result = await fetchPayrollSystemsRequest(storeState.user.accessToken);
      return result;
    } catch (error: any) {
      return error?.response?.status !== 404 && rejectWithValue(error);
    }
  },
);

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    resetOrganizations: (state) => {
      state.list = initialState.list;
      state.organization = initialState.organization;
    },
    resetOrganization: (state) => {
      state.organization = initialState.organization;
      state.organizationConfig = initialState.organizationConfig;
    },
    resetShouldGoIntoMaintenancePage: (state) => {
      state.shouldGoIntoMaintenanceScreen = initialState.shouldGoIntoMaintenanceScreen;
    },
    setOrganizationsPage: (state, action) => {
      state.pageNumber = action.payload;
    },
    setOrganizationsSearch: (state, action) => {
      state.search = action.payload;
    },
    setOrganizationsTab: (state, action) => {
      state.tab = action.payload;
    },
    setOrganizationConfig: (state, action) => {
      state.organizationConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllOrganizations.pending, (state) => {
      state.list = initialState.list;
      state.pendingList = true;
    });

    builder.addCase(fetchAllOrganizations.fulfilled, (state, action) => {
      state.list = action.payload.values;
      state.pendingList = false;
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
      state.totalSize = action.payload.totalSize;
      state.numPages = (Math.ceil(action.payload.totalSize / initialState.pageSize));
    });

    builder.addCase(fetchAllOrganizations.rejected, (state) => {
      state.pendingList = false;
      state.shouldGoIntoMaintenanceScreen = true;
    });

    builder.addCase(fetchDefaultConfig.fulfilled, (state, action) => {
      state.defaultConfig = action.payload;
    });

    builder.addCase(createOrganization.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(createOrganization.fulfilled, (state, action) => {
      state.organization = {
        id: action.payload.id,
        name: action.payload.name,
        externalId: action.payload.externalId,
      };
      state.pending = false;
    });

    builder.addCase(createOrganization.rejected, (state) => {
      state.pending = false;
    });

    builder.addCase(fetchOrganizationByIdWithConfigAndSectionStatuses.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(fetchOrganizationByIdWithConfigAndSectionStatuses.fulfilled, (state, action) => {
      const [organization, organizationConfig, sectionStatuses] = action.payload;

      state.pending = false;
      state.organization = organization;
      state.organizationConfig = organizationConfig;
      state.sectionStatuses = sectionStatuses;
    });

    builder.addCase(fetchOrganizationByIdWithConfigAndSectionStatuses.rejected, (state) => {
      state.pending = false;
      state.organization = initialState.organization;
      state.organizationConfig = initialState.organizationConfig;
      state.sectionStatuses = initialState.sectionStatuses;
    });

    builder.addCase(createOrganizationSectionStatuses.fulfilled, (state, action) => {
      state.sectionStatuses.push(action.payload);
    });

    builder.addCase(editOrganizationSectionStatuses.fulfilled, (state, action) => {
      state.sectionStatuses = [
        ...state.sectionStatuses.filter((item) => item?.id !== action.payload?.id),
        action.payload,
      ];
    });

    builder.addCase(fetchOrganizationConfigs.fulfilled, (state, action) => {
      state.organizationConfig = action.payload;
    });

    builder.addCase(fetchOrganizationConfigs.rejected, (state) => {
      state.organization = initialState.organization;
      state.organizationConfig = initialState.organizationConfig;
    });

    builder.addCase(editOrganization.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(editOrganization.fulfilled, (state, action) => {
      const [organization, organizationConfigs] = action.payload;
      state.organization = organization;
      state.organizationConfig = organizationConfigs;
      state.pending = false;
    });

    builder.addCase(editOrganization.rejected, (state) => {
      state.pending = false;
    });

    builder.addCase(editOrganizationConfigs.fulfilled, (state, action) => {
      state.organizationConfig = action.payload;
    });

    builder.addCase(editOrganizationConfigs.rejected, (state) => {
      state.pending = false;
    });

    builder.addCase(resetOrganizationEvent, (state) => {
      state.organization = initialState.organization;
      state.organizationConfig = initialState.organizationConfig;
      state.sectionStatuses = initialState.sectionStatuses;
      state.pending = initialState.pending;
    });

    builder.addCase(editOrganizationEWASettings.pending, (state) => {
      state.pendingConfigs = true;
    });

    builder.addCase(editOrganizationEWASettings.fulfilled, (state, action) => {
      state.pendingConfigs = false;
      state.organizationConfig = action.payload;
    });

    builder.addCase(editOrganizationEWASettings.rejected, (state) => {
      state.pendingConfigs = false;
    });

    builder.addCase(editOrganizationTPOSettings.pending, (state) => {
      state.pendingConfigs = true;
    });

    builder.addCase(editOrganizationTPOSettings.fulfilled, (state, action) => {
      state.pendingConfigs = false;
      state.organizationConfig = action.payload;
    });

    builder.addCase(editOrganizationTPOSettings.rejected, (state) => {
      state.pendingConfigs = false;
    });

    builder.addCase(editOrganizationPayrollSettings.pending, (state) => {
      state.pendingConfigs = true;
    });

    builder.addCase(editOrganizationPayrollSettings.fulfilled, (state, action) => {
      state.pendingConfigs = false;
      state.organizationConfig = action.payload;
    });

    builder.addCase(editOrganizationPayrollSettings.rejected, (state) => {
      state.pendingConfigs = false;
    });

    builder.addCase(editOrganizationTipSourceConfiguration.pending, (state) => {
      state.pendingConfigs = true;
      state.organizationTipSourceConfigSuccessfullySaved = initialState.organizationTipSourceConfigSuccessfullySaved;
    });

    builder.addCase(editOrganizationTipSourceConfiguration.fulfilled, (state, action) => {
      state.pendingConfigs = false;
      state.organizationConfig = action.payload;
      state.organizationTipSourceConfigSuccessfullySaved = true;
    });

    builder.addCase(editOrganizationTipSourceConfiguration.rejected, (state) => {
      state.pendingConfigs = false;
      state.organizationTipSourceConfigSuccessfullySaved = false;
    });

    builder.addCase(fetchEmployeesWithConfigsLocationsAndGroups.fulfilled, (state, action) => {
      const defaultConfigs = action.payload[1];

      state.defaultConfig = defaultConfigs;
    });

    builder.addCase(saveOrganizationTPOSettingsBanking.pending, (state) => {
      state.tpoBankingSettings.pendingTPOBankingConfigs = true;
    });

    builder.addCase(saveOrganizationTPOSettingsBanking.fulfilled, (state, action) => {
      state.tpoBankingSettings.pendingTPOBankingConfigs = false;
      state.tpoBankingSettings.config = action.payload;
    });

    builder.addCase(saveOrganizationTPOSettingsBanking.rejected, (state) => {
      state.tpoBankingSettings.pendingTPOBankingConfigs = false;
    });

    builder.addCase(fetchOrganizationDirectDepositSettingsBanking.pending, (state) => {
      state.pendingOrganizationBankingConfig = true;
    });

    builder.addCase(fetchOrganizationDirectDepositSettingsBanking.fulfilled, (state, action) => {
      state.organizationBankingConfig = action.payload;
      state.pendingOrganizationBankingConfig = false;
    });

    builder.addCase(fetchOrganizationDirectDepositSettingsBanking.rejected, (state) => {
      state.organizationBankingConfig = initialState.organizationBankingConfig;
      state.pendingOrganizationBankingConfig = false;
    });

    builder.addCase(fetchOrganizationPayrollSystemSettings.pending, (state) => {
      state.pendingAutomaticBankDetailsUpdates = true;
    });

    builder.addCase(fetchOrganizationPayrollSystemSettings.fulfilled, (state, action) => {
      state.automaticBankDetailsUpdates = action.payload;
      state.pendingAutomaticBankDetailsUpdates = false;
    });

    builder.addCase(fetchOrganizationPayrollSystemSettings.rejected, (state) => {
      state.automaticBankDetailsUpdates = initialState.automaticBankDetailsUpdates;
      state.pendingAutomaticBankDetailsUpdates = false;
    });

    builder.addCase(fetchPayrollSystems.pending, (state) => {
      state.pendingPayrollSystems = true;
      state.rejectedPayrollSystems = false;
    });

    builder.addCase(fetchPayrollSystems.fulfilled, (state, action) => {
      state.payrollSystems = action.payload;
      state.pendingPayrollSystems = false;
      state.rejectedPayrollSystems = false;
    });

    builder.addCase(fetchPayrollSystems.rejected, (state) => {
      state.payrollSystems = initialState.payrollSystems;
      state.pendingPayrollSystems = false;
      state.rejectedPayrollSystems = true;
    });
  },
});

export const {
  resetOrganizations,
  resetOrganization,
  setOrganizationsPage,
  setOrganizationsSearch,
  setOrganizationsTab,
  resetShouldGoIntoMaintenancePage,
  setOrganizationConfig,
} = organizationsSlice.actions;

export const organizationsSelector = (state: RootState): Organization[] => state.organizations.list;
export const organizationsPendingSelector = (state: RootState): boolean => state.organizations.pending;
export const organizationsPendingConfigsSelector = (state: RootState): boolean => state.organizations.pendingConfigs;
export const organizationsPendingListSelector = (state: RootState): boolean => state.organizations.pendingList;
export const defaultConfigSelector = (state: RootState) => state.organizations.defaultConfig;
export const organizationConfigSelector = (state: RootState)
  : OrganizationConfigResponseType[] | null => state.organizations.organizationConfig;
export const organizationSelector = (state: RootState) => state.organizations.organization;
export const organizationSectionStatusesSelector = (state: RootState) => state.organizations.sectionStatuses;
export const organizationsPageNumberSelector = (state: RootState) => state.organizations.pageNumber;
export const organizationsPageSizeSelector = (state: RootState) => state.organizations.pageSize;
export const organizationsNumPagesSelector = (state: RootState) => state.organizations.numPages;
export const organizationsTotalSizeSelector = (state: RootState) => state.organizations.totalSize;
export const organizationsTabSelector = (state: RootState): OrganizationStatuses => state.organizations.tab;
export const organizationsSearchSelector = (state: RootState): string => state.organizations.search;
export const maintenanceSelector = (state: RootState): boolean => state.organizations.shouldGoIntoMaintenanceScreen;
export const organizationBankingConfigSelector = (state: RootState) => state.organizations.organizationBankingConfig;
export const organizationPendingBankingConfigSelector = (state: RootState): boolean => state.organizations.pendingOrganizationBankingConfig;
export const organizationTipSourceConfigSuccessfullySavedSelector = (state: RootState) => state.organizations.organizationTipSourceConfigSuccessfullySaved;
export const organizationAutomaticBankDetailsUpdatesSelector = (state: RootState) => state.organizations.automaticBankDetailsUpdates;
export const organizationPendingAutomaticBankDetailsUpdatesSelector = (state: RootState): boolean => state.organizations.pendingAutomaticBankDetailsUpdates;
export const payrollSystemsSelector = (state: RootState) => state.organizations.payrollSystems;
export const pendingPayrollSystemsSelector = (state: RootState) => state.organizations.pendingPayrollSystems;
export const rejectedPayrollSystemsSelector = (state: RootState) => state.organizations.rejectedPayrollSystems;

export default organizationsSlice.reducer;
