import { ReactNode, useContext } from 'react';
import { SIZE } from 'baseui/input';
import {
  Cell,
  ALIGNMENT,
  Responsive,
  CellProps,
  Alignment,
} from 'baseui/layout-grid';
import {
  FormControlProps,
} from 'baseui/form-control';
import {
  FormikHandlers, FormikHelpers, FormikState,
} from 'formik';
import { PinCode, PinCodeProps } from 'baseui/pin-code';
import AppFormControl from './AppFormControl';

export type AppPinCodePropsType = {
  name: string
  context: any
  label?: any
  cellSpan?: Responsive<number> | undefined
  cellAlign?: Alignment | undefined
  cellProps?: CellProps
  inputProps?: PinCodeProps
  formControlProps?: Pick<FormControlProps, Exclude<keyof FormControlProps, 'children'>>
  placeholder?: string
  showStar?: boolean
  tooltipTitle?: ReactNode,
  tooltipContent?: ReactNode,
  dynamicErrors?: boolean,
  borderColor?: string,
  type?: string,
  disabled?: boolean,
}

const AppPinCode = ({
  label,
  name,
  context,
  cellSpan = 3,
  cellAlign = ALIGNMENT.start,
  cellProps,
  inputProps,
  formControlProps,
  tooltipTitle,
  tooltipContent,
  dynamicErrors = false,
  borderColor,
  placeholder,
  disabled,
}: AppPinCodePropsType) => {
  const formik = useContext<FormikState<any> & FormikHelpers<any> & FormikHandlers>(context);

  const {
    errors,
    values,
    touched,
    setFieldTouched,
    setFieldValue,
    handleChange,
  } = formik;

  return (
    <Cell
      span={cellSpan}
      align={cellAlign}
      {...cellProps}
    >
      <AppFormControl
        tooltipTitle={tooltipTitle}
        tooltipContent={tooltipContent}
        label={label}
        error={!!(errors[name] && touched[name]) && errors[name]}
        {...formControlProps}
      >
        <PinCode
          size={SIZE.compact}
          values={values[name]}
          placeholder={placeholder}
          error={!!(errors[name] && touched[name] && errors[name])}
          disabled={disabled}
          overrides={{
            Root: {
              props: {
                id: `Form-pin-code-${name}`,
                'data-testid': `Form-pin-code-${name}`,
              },
              style: () => ({
                borderColor,
              }),
            },
            ...inputProps?.disabled === true ? { MaskToggleButton: () => null } : '',
          }}
          onChange={({ event, values: pinValues }) => {
            setFieldValue(name, pinValues);
            handleChange(event);
            dynamicErrors && setFieldTouched(name, true, false);
          }}
          {...inputProps}
        />
      </AppFormControl>
    </Cell>
  );
};

export default AppPinCode;
