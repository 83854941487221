import { StyleObject } from 'styletron-react';
import { borderRadius } from 'theme';

export const contentContainerStyles = {
  padding: '0',
  background: '#F6F6F6',
  zIndex: 50,
  minHeight: 'calc(100vh - 72px)',
  width: '100%',
  boxSizing: 'border-box' as any,
  marginTop: '24px',
};

export const containerStyles = {
  display: 'inherit',
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
};

export const contentLocationContainerStyles = {
  background: '#fff',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  border: '1px solid #E2E2E2',
  'box-sizing': 'border-box',
  padding: '8px',
  width: '100%',
  height: '100%',
};

export const listWithBorder = {
  padding: '8px',
  margin: '24px 24px 24px -24px',
  borderBottom: '1px solid #E2E2E2',
};

export const listItemName = {
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '24px',
};

export const listItemInfoStyles = {
  display: 'flex',
  'flex-direction': 'row',
  gap: '32px',
  'flex-wrap': 'wrap',
  '@media screen and (max-width: 768px)': {
    gap: '8px',
  },
};

export const details = {
  display: 'flex',
  'flex-direction': 'row',
  gap: '8px',
  marginTop: '6px',
  marginBottom: '16px',
  '@media screen and (max-width: 768px)': {
    margin: '4px',
  },
};

export const listLabel = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#545454',
};

export const listEmployeeItem = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
};

export const PAYMENTS_TABLE_STYLES = {
  // eslint-disable-next-line max-len
  gridTemplateColumns: 'minmax(7%, max-content) minmax(20%, max-content) minmax(12%, max-content) minmax(12%, max-content) minmax(12%, max-content) minmax(12%, max-content) minmax(10%, max-content) minmax(15%, max-content)',
  '@media (max-width: 1200px)': {
    gridTemplateColumns: '100px 350px 150px 150px 150px 150px 150px 200px',
  },
};

export const MODAL_TABLE_STYLES = {
  // eslint-disable-next-line max-len
  gridTemplateColumns: 'minmax(20%, max-content) minmax(15%, max-content) minmax(20%, max-content) minmax(20%, max-content) minmax(20%, max-content) minmax(15%, max-content) minmax(20%, max-content) minmax(15%, max-content) minmax(15%, max-content) minmax(15%, max-content)',
  '@media (max-width: 1200px)': {
    gridTemplateColumns: '150px 150px 200px 200px 200px 100px 200px 200px 200px 200px',
  },
};

export const TABLE_STYLES = {
  // eslint-disable-next-line max-len
  gridTemplateColumns: 'minmax(22%, max-content) minmax(10%, max-content) minmax(10%, max-content) minmax(10%, max-content) minmax(8%, max-content) minmax(15%, max-content) minmax(10%, max-content) minmax(15%, max-content)',
  '@media (max-width: 1200px)': {
    gridTemplateColumns: '350px 150px 150px 150px 150px 150px 150px 250px',
  },
};

export const firstColumnWidth = '20%';
export const secondColumnWidth = '80%';

export const cellOverrides = {
  Cell: {
    style: {
      fontWeight: 400,
    },
  },
};

export const firstColumnWidthOverrides = {
  Block: {
    style: {
      margin: '0px',
      '@media screen and (max-width: 800px)': {
        width: '100%',
      },
      '@media screen and (max-width: 1320px)': {
        width: '30%',
      },
      '@media screen and (min-width: 1320px)': {
        width: '25%',
      },
    },
  },
};

export const secondColumnWidthOverrides = {
  Block: {
    style: {
      margin: '0px',
      '@media screen and (max-width: 800px)': {
        width: '100%',
      },
      '@media screen and (max-width: 1320px)': {
        width: '70%',
      },
      '@media screen and (min-width: 1320px)': {
        width: '75%',
      },
    },
  },
};

export const popoverExportOverrides: any = {
  display: 'flex',
  gap: '8px',
  padding: '8px',
  background: '#FFFFFF',
  color: '#000000',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const listContainerStyles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '120px',
} as StyleObject;

export const LOADER_STYLE = {
  background: '#F6F6F6',
};

export const cellSmallLaptopsStyle = {
  width: 'auto !important',
  flexShrink: 0,
  minWidth: '226px',
};

export const blockStyles = {
  margin: '0 -16px',
  '@media screen and (max-width: 599px)': {
    margin: '0 -8px',
  },
};

export const containerViewByStyles = {
  '@media screen and (max-width: 767px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

export const viewByLeftBlockStyles = {
  '@media screen and (max-width: 767px)': {
    width: '100%',
    flexDirection: 'column',
  },
};

export const viewByRightBlockStyles = {
  margin: '20px 18px 0 18px',
  '@media screen and (max-width: 767px)': {
    width: '100%',
    margin: '8px 18px',
  },
  '@media screen and (max-width: 600px)': {
    margin: '8px 8px',
  },
};

export const viewByRightBlockBusinessDayStyles = {
  margin: '20px 18px 0 18px',
  '@media screen and (max-width: 900px)': {
    width: '100%',
    margin: '8px 18px',
    justifyContent: 'flex-start',
  },
  '@media screen and (max-width: 600px)': {
    margin: '8px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};
