import { OrganizationIDType } from 'types/OrganizationTypes';
import request from './request';
import { SaveOrganizationPayrollSystemSettingsRequestTypes } from '../types/DirectDepositTypes';

export const fetchOrganizationPayrollSystemSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
) => request({
  token,
  url: `/organizations/${organizationID}/configuration`,
});

export const fetchPayrollSystemsRequest = (
  token: string,
) => request({
  token,
  url: '/payroll-systems',
});

export const saveOrganizationPayrollSystemSettingsRequest = (
  token: string,
  organizationID: OrganizationIDType,
  data: SaveOrganizationPayrollSystemSettingsRequestTypes,
) => request({
  token,
  method: 'POST',
  payload: data,
  url: `/organizations/${organizationID}/configuration`,
});
