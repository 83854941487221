import { MoneyDtoType } from './CommonTypes';
import { OrganizationIDType } from './OrganizationTypes';

export type BatchesFormFilterPropsType = {
  title?: string
  handleClickCancel: () => void
  handleClickSave: () => void
  buttonsDisabled: boolean
}

export type BatchIDType = string | undefined;

export type FetchBatchParamsType = {
  batchID: BatchIDType,
}
export type DrawTransactionType = {
  id: string
  name?: string
  selected?: boolean | undefined
}

export enum BatchStatuses {
  NEW = 'NEW',
  SENT_TO_PAYROLL = 'SENT_TO_PAYROLL',
  PAID_IN_FULL = 'PAID_IN_FULL',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum BatchCreationTriggers {
  ADMIN_API = 'ADMIN_API',
  SCHEDULER = 'SCHEDULER',
}

export type SubElementsAmount = {
  value: number
  scale: number
  currencyCode: string
}

export type BatchAmount = {
  value: number
  scale: number
  currencyCode: string
}

export interface BatchItem {
  id: number
  payrollPeriodId: number
  externalBatchId: string
  batchReference: string
  filename: string
  payrollPeriodStartDate: string
  payrollPeriodEndDate: string
  payGroupId: number
  payGroupPayFrequencyRule: string
  subElementsAmount: SubElementsAmount
  status: BatchStatuses
  workersCount: number
  amountDue: MoneyDtoType
  creationDate: string
  deductionCount: number
  includeInFileDeductionCount: number
  creationTrigger: BatchCreationTriggers
  type: string,
  amount?: BatchAmount
}

export type FetchAllBatchesParamsType = {
  organizationID: OrganizationIDType
  pageNumber: string
  payGroupId: string,
  payrollPeriodId: string
  status?: string
  creationTrigger?: string
  deductionCount?: number
  creationDate?: string
  types?: string
}
