import { store } from 'store';

const state = store.getState();
const { application } = state || {};
const { priceScale } = application || {};

export const scalePrice = (
  amount: number,
  scale: number = priceScale,
): number => {
  const scaled = amount * 10 ** scale;
  return Math.round(scaled);
};

export const unScalePrice = (
  amount: number,
  scale: number = priceScale,
): number => {
  const unscaled = amount / 10 ** scale;
  return parseFloat(unscaled.toFixed(scale));
};
