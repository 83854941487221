import { faCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Block } from 'baseui/block';
import { Cell, Grid } from 'baseui/layout-grid';
import { Tag, VARIANT } from 'baseui/tag';
import { LabelMedium, LabelSmall, ParagraphSmall } from 'baseui/typography';
import AppGridTable from 'components/AppGridTable/AppGridTable';
import Loader from 'components/Loader';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { emptyPlaceholder } from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import { useStyletron } from 'baseui';
import { memo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchTippedEmployeeBalanceOfferById,
  tippedEmployeeTPOPaymentsListSelector,
  tippedEmployeeTPOPaymentsPendingListSelector,
} from 'store/slices/tpo';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { TPOPayment } from 'types/TPOTypes';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { Card } from 'baseui/card';
import { TETHER_PLACEMENT } from 'baseui/layer';
import AppTooltip from 'components/Form/AppTooltip';
import { SubElementPaymentFailedStatusType } from 'types/EmployeeTypes';
import { PAYMENTS_TABLE_STYLES } from '../TipManagementHelper';
import TippedEmployeeTPOPaymentDetailsModal from './TippedEmployeeTPOPaymentDetailsModal';
import TippedEmployeeTPOPaymentsOfferModal from './TippedEmployeeTPOPaymentsOfferModal';

const TippedEmployeeTPOPaymentsTable = () => {
  const [css, theme] = useStyletron();
  const { t } = useTranslation(['tipsManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const dateFormat = t('dateFormats:standard-day-of-week-and-year');
  const pending = useAppSelector(tippedEmployeeTPOPaymentsPendingListSelector);
  const modals = useAppSelector(modalsSelector);
  const [clickedPayment, setClickedPayment] = useState<TPOPayment>();

  const payments = useAppSelector(tippedEmployeeTPOPaymentsListSelector);

  const NEUTRAL = {
    tagColor: theme.colors.backgroundTertiary,
    labelColor: theme.colors.contentSecondary,
  };

  const WARNING = {
    tagColor: theme.colors.backgroundWarningLight,
    labelColor: '#674D1B',
  };

  const EMPLOYEE_STATUS_TO_TAG_CONFIGS: {
    [index: string]: { tagColor: string, labelColor: string },
  } = {
    CREATED: NEUTRAL,
    ACCEPTED: NEUTRAL,
    PROCESSED: NEUTRAL,
    COMPLETED: NEUTRAL,
    SETTLED: NEUTRAL,
    PARTIALLY_SETTLED: NEUTRAL,
    DISPUTED: WARNING,
    VOIDED: WARNING,
    DECLINED: WARNING,
    REFUNDED: WARNING,
    NOT_PAID: WARNING,
  };

  const headingCells = [
    <LabelMedium>{t('tipsManagement:table.header.status.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.date.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.earnedToDay.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.payableToDay.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.previousPayments.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.paidThisDay.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.offerID.label')}</LabelMedium>,
    <LabelMedium>{t('tipsManagement:table.header.paymentInfo.label')}</LabelMedium>,
  ];

  const handlePaymentIDClick = (selectedPayment: TPOPayment) => {
    setClickedPayment(selectedPayment);
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_TPO_PAYMENT_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const handleOfferIDClick = (selectedOffer: number | undefined) => {
    dispatch(fetchTippedEmployeeBalanceOfferById({ offerID: String(selectedOffer) }));
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  return (
    <>
      <Block marginTop="32px" marginLeft="-24px">
        <Loader active={pending} />
        <Grid>
          <Cell span={[12]}>
            {payments && payments.length > 0 ? (
              <AppGridTable
                tableStyle={PAYMENTS_TABLE_STYLES}
                tableGridTemplateColumns={PAYMENTS_TABLE_STYLES.gridTemplateColumns}
                headingCells={headingCells}
                rows={payments?.map((payment: any) => {
                  const statusStyles = EMPLOYEE_STATUS_TO_TAG_CONFIGS[payment.status as keyof typeof EMPLOYEE_STATUS_TO_TAG_CONFIGS];
                  return (
                    {
                      id: payment.id,
                      cells: [
                        <ParagraphSmall color={theme.colors.contentSecondary}>
                          { payment.status === 'CREATED'
                          || payment.status === 'ACCEPTED'
                          || payment.status === 'PROCESSED'
                          || payment.status === 'COMPLETED'
                          || payment.status === 'SETTLED'
                          || payment.status === 'PARTIALLY_SETTLED' ? (
                            <FontAwesomeIcon
                              className={css({ color: '#048848' })}
                              icon={faCheck}
                            />
                            ) : (
                              <FontAwesomeIcon
                                className={css({ color: '#FFC043' })}
                                icon={faCircleExclamation}
                              />
                            )}
                        </ParagraphSmall>,
                        <ParagraphSmall color={theme.colors.contentSecondary}>
                          {moment(payment.requestedDate).format(dateFormat)}
                        </ParagraphSmall>,
                        <ParagraphSmall color={theme.colors.contentSecondary}>
                          {priceFormatter().format(unScalePrice(payment.tipsEarnedToDate.value)) || emptyPlaceholder}
                        </ParagraphSmall>,
                        <ParagraphSmall color={theme.colors.contentSecondary}>
                          {priceFormatter().format(unScalePrice(payment.tipsPayable.value)) || emptyPlaceholder}
                        </ParagraphSmall>,
                        <ParagraphSmall color={theme.colors.contentSecondary}>
                          {priceFormatter().format(unScalePrice(payment.previousPaymentsAmount.value)) || emptyPlaceholder}
                        </ParagraphSmall>,
                        <ParagraphSmall
                          onClick={() => handlePaymentIDClick(payment)}
                          color="#CC0033"
                          overrides={{
                            Block: {
                              style: {
                                cursor: 'pointer',
                                textDecorationLine: 'underline',
                              },
                            },
                          }}
                        >
                          {priceFormatter().format(unScalePrice(payment.balance.value)) || emptyPlaceholder}
                        </ParagraphSmall>,
                        <ParagraphSmall
                          onClick={() => handleOfferIDClick(payment.tcoOfferId)}
                          color="#CC0033"
                          overrides={{
                            Block: {
                              style: {
                                cursor: 'pointer',
                                textDecorationLine: 'underline',
                              },
                            },
                          }}
                        >
                          {payment.tcoOfferId}
                        </ParagraphSmall>,
                        <Tag
                          closeable={false}
                          variant={VARIANT.solid}
                          overrides={{
                            Root: {
                              style: {
                                backgroundColor: statusStyles.tagColor,
                              },
                            },
                            Text: {
                              style: {
                                maxWidth: 'unset',
                              },
                            },
                          }}
                        >
                          <Block
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <LabelSmall color={statusStyles.labelColor} width="fit-content">
                              {`${t(`tipsManagement:payments.statuses.${payment?.status}`)}`}
                            </LabelSmall>
                            {payment.statusDetails
                             && (
                             <AppTooltip
                               title={t('tipsManagement:statuses.PAYMENT_FAILED')}
                               content={t(`tipsManagement:payments.failed.statuses.${payment.statusDetails as SubElementPaymentFailedStatusType}`)}
                               placement={TETHER_PLACEMENT.right}
                               iconColor="rgb(153, 111, 0)"
                             />
                             )}
                          </Block>
                        </Tag>,
                      ],
                    }
                  );
                })}
              />
            ) : (
              <Block
                marginTop="8px"
                marginBottom="8px"
              >
                <Grid gridMargins={0}>
                  <Cell span={12}>
                    <Card>
                      {t('tipsManagement:payments.noResults')}
                    </Card>
                  </Cell>
                </Grid>
              </Block>
            )}
          </Cell>
        </Grid>
      </Block>
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_TPO_PAYMENT_DETAILS_MODAL) && <TippedEmployeeTPOPaymentDetailsModal payment={clickedPayment} />}
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL) && <TippedEmployeeTPOPaymentsOfferModal />}
    </>
  );
};

export default memo(TippedEmployeeTPOPaymentsTable);
