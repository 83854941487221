import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  setWorkerProfileAttributesTrusted,
  workerProfileAttributesTrustedPendingSelector,
} from 'store/slices/workers';
import {
  NotificationType,
  setNotification,
} from 'store/slices/notification';
import {
  colors,
} from 'theme';
import { Button, KIND } from 'baseui/button';
import { StatefulMenu } from 'baseui/menu';
import { StatefulPopover } from 'baseui/popover';
import { PLACEMENT } from 'baseui/toast';
import { WorkerActionsPropsType } from 'types/WorkerTypes';
import { SIZE, Spinner } from 'baseui/spinner';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { AccessUnit } from 'components/Access/Access';
import { userAccessUnitsSelector } from 'store/slices/user';
import { setPrevPage } from 'store/slices/application';

/**
 * WorkerActions component
 *
 * @param worker worker data
 * @param setIsEmploymentsModalOpen if WORKER_EMPLOYMENTS_MODAL is open
 * @param setIsLinkedAccountsModalOpen if WORKER_LINKED_ACCOUNTS_MODAL is open
 * @param setIsShiftsModalOpen if WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL is open
 * @param setUpdateWorkerDetailsOpen if WORKER_UPDATE_DETAILS_MODAL is open
 */

interface IItem {
  label: string;
  'data-testid': string;
  id: string;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

const roles = [AccessUnit.FBOManager, AccessUnit.FBOReader];

const WorkerActions = ({
  worker,
  setIsEmploymentsModalOpen,
  setIsLinkedAccountsModalOpen,
  setIsShiftsModalOpen,
  setUpdateWorkerDetailsOpen,
}: WorkerActionsPropsType) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation(['common', 'workers', 'employees']);
  const userAccessUnits = useAppSelector(userAccessUnitsSelector);
  const setTrustUserPending = useAppSelector(workerProfileAttributesTrustedPendingSelector);
  const isUserFBO = !!roles?.some((item) => userAccessUnits?.includes(item));
  const [clickedWorkerID, setClickedWorkerID] = useState<string>();
  const [isTrustedActionClicked, setIsTrustedActionClicked] = useState<boolean>(false);
  const [isButtonChangeTrustDisabled, setIsButtonChangeTrustDisabled] = useState<boolean>(false);

  const handleTrustUserAction = (trusted: boolean) => {
    if (worker.id) {
      setIsTrustedActionClicked(trusted);
      setClickedWorkerID(worker.id);
      dispatch(setWorkerProfileAttributesTrusted({
        workerID: worker.id,
        data: {
          trusted,
        },
      })).then(() => {
        setClickedWorkerID('');
        setIsButtonChangeTrustDisabled(true);
      });
      dispatch(setNotification({
        type: NotificationType.INFO,
        isOpen: true,
        autoHideDuration: 1500,
        title: undefined,
        titleKey: undefined,
        text: t('notifications:action.inProgress'),
        textKey: undefined,
      }));
    }
  };

  const listOfDropdownOptions: IItem[] = [
    {
      label: t('workers:employmentsButton'),
      'data-testid': `Workers-${worker.id}-employments-btn`,
      id: `Workers-${worker.id}-employments-btn`,
      onClick: () => {
        dispatch(setPrevPage('/workers'));
        setIsEmploymentsModalOpen(true, String(worker.id));
      },
    },
    ...(isUserFBO ? [{
      label: t('workers:viewTransactions'),
      'data-testid': `Workers-${worker.id}-fboAccount-btn`,
      id: `Workers-${worker.id}-fboAccount-btn`,
      onClick: () => {
        dispatch(setPrevPage('/workers'));
        history.push(`/treasury/transaction-history/${worker?.id}`);
      },
    }] : []),
    ...(isUserFBO ? [{
      label: t('workers:linkedAccounts'),
      'data-testid': `Workers-${worker.id}-linkedAccounts-btn`,
      id: `Workers-${worker.id}-linkedAccounts-btn`,
      onClick: () => setIsLinkedAccountsModalOpen(true, String(worker.id)),
    }] : []),
    ...(worker.knownSource
      ? [{
        label: t('workers:untrustUserButton'),
        'data-testid': `Workers-${worker.id}-markAsNOTTrusted-btn`,
        id: `Workers-${worker.id}-markAsNOTTrusted-btn`,
        onClick: () => handleTrustUserAction(false),
        disabled: isButtonChangeTrustDisabled,
        isLoading: setTrustUserPending && !isTrustedActionClicked && clickedWorkerID === worker.id,
      }]
      : [{
        label: t('workers:trustUserButton'),
        'data-testid': `Workers-${worker.id}-markAsTrusted-btn`,
        id: `Workers-${worker.id}-markAsTrusted-btn`,
        onClick: () => handleTrustUserAction(true),
        disabled: isButtonChangeTrustDisabled,
        isLoading: setTrustUserPending && isTrustedActionClicked && clickedWorkerID === worker.id,
      }]
    ),
    {
      label: t('workers:shiftsButton'),
      'data-testid': `Workers-${worker.id}-shifts-btn`,
      id: `Workers-${worker.id}-shifts-btn`,
      onClick: () => setIsShiftsModalOpen(true, String(worker.id)),
    },
    {
      label: t('workers:updateWorkerDetails'),
      'data-testid': `Workers-${worker.id}-update-details-btn`,
      id: `Workers-${worker.id}-update-details-btn`,
      onClick: () => setUpdateWorkerDetailsOpen(true, worker),
    },
  ];

  return (
    <StatefulPopover
      initialState={{ isOpen: false }}
      content={({ close }) => (
        <StatefulMenu
          onItemSelect={({ item }) => {
            !item.isLoading && item.onClick(item);
            close();
          }}
          getRequiredItemProps={(item) => ({
            id: item.id,
            'data-testid': item['data-testid'],
          })}
          items={listOfDropdownOptions.map((item) => ({
            ...item,
            label: item.isLoading ? (
              <Block
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="20px"
              >
                <Spinner
                  style={{
                    borderRightColor: colors.primary,
                    borderTopColor: colors.primary,
                    borderLeftColor: colors.primary,
                    borderBottomColor: 'transparent',
                    width: 12,
                    height: 12,
                  }}
                  $size={SIZE.small}
                />
              </Block>
            ) : (
              item.label
            ),
          }))}
        />
      )}
      accessibilityType="menu"
      placement={PLACEMENT.bottomRight}
    >
      <Button
        kind={KIND.tertiary}
        overrides={{
          Root: {
            props: {
              id: `Workers-${worker.id}-actions-btn`,
              'data-testid': `Workers-${worker.id}-actions-btn`,
            },
            style: {
              color: colors.primary,
              backgroundColor: 'inherit',
              ':hover': {
                backgroundColor: 'inherit',
              },
            },
          },
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
        />
      </Button>
    </StatefulPopover>
  );
};

export default WorkerActions;
