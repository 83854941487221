import { SelectedField, WorkersDetailsUpdateValuesType } from 'types/WorkerTypes';

const verficationCodeDataMapper = (
  selectedField: string,
  values: WorkersDetailsUpdateValuesType,
): any => {
  if (selectedField === SelectedField.Email) {
    return {
      email: values.email,
    };
  }
  return null;
};

export default verficationCodeDataMapper;
