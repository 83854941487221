import { OrganizationIDType } from 'types/OrganizationTypes';
import { MoneyDtoType } from './CommonTypes';
import { LocationIDType } from './LocationTypes';

export interface Worker {
  id: string
  username: string
  firstName: string
  lastName: string
  displayName: string
  email: string
  mobilePhone: string
  mobileCountryCode?: string
  phone: string
  country: string
  state: string
  city: string
  streetAddress: string
  streetAddressLine2: string | null
  postalCode: string
  preferredLanguage: string
  mobileVerified: boolean
  emailVerified: boolean
  userSource: string
  userType: string
  fuegoPaycardIssued?: boolean
  fuegoCardIssued?: boolean
  knownSource?: boolean
}
export interface WorkerEmployments {
  id: string,
  workerId: string,
  organization: {
    id: OrganizationIDType
    name: string
  },
  externalUserId: string,
  status?: WorkerEmploymentStatusTypes,
  statusEffectiveDate: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  payrollId: string,
  organizationName: string
}

export type WorkerIDType = string | null | undefined;

export type FetchWorkerParamsType = {
  workerID: WorkerIDType,
}

export type FetchWorkersParamsType = {
  filter?: FetchWorkersFilterType
}

export type FetchEmployeeOfferParamsType = {
  organizationID: OrganizationIDType,
  employeeID: string,
}

export type WorkerResultPropsType = {
  worker: Partial<Worker> | null | undefined,
}

export type FetchWorkersFilterType = {
  firstName?: string,
  lastName?: string,
  email?: string,
  workerId?: string,
  namePattern?: string,
  nextToken?: string,
}

export enum WorkerEmploymentStatusTypes {
  PENDING ='PENDING',
  LINKED = 'LINKED',
  REJECTED = 'REJECTED',
  AUTO_LINKED = 'AUTO_LINKED',
}

export type WorkerEmploymentLinkParamsType = {
  status: WorkerEmploymentStatusTypes,
  organizationId: string,
  pageNumber: string,
  pageSize?: string,
  firstName?: string,
  lastName?: string,
}

export type SetWorkerProfileAttributesTrustedRequest = {
  trusted: boolean
}

export type SetWorkerProfileAttributesTrustedParamsType = {
  workerID: WorkerIDType,
  data: SetWorkerProfileAttributesTrustedRequest
}

export interface DailyShifts {
  date: string,
  openShiftsCount: number,
  shifts: Shift[]
}

export interface Shift {
    id: number,
    date?: string,
    organizationName: string,
    locationName: string,
    locationTimeZone: string,
    jobName: string,
    start: string,
    end: string,
    source: string,
    earningAmount: MoneyDtoType
}

export type FetchUniversalCalendarDailyShiftsPropsType = {
  workerID: WorkerIDType
  fromDate?: string,
  organizationID?: OrganizationIDType,
  locationID?: LocationIDType,
}

export type WorkerListItemPropsType = {
  worker: Partial<Worker>,
  setIsEmploymentsModalOpen: (isOpen: boolean, workerId: string) => void,
  setIsLinkedAccountsModalOpen: (isOpen: boolean, workerId: string) => void,
  setIsShiftsModalOpen: (isOpen: boolean, workerId: string) => void,
  setUpdateWorkerDetailsOpen: (isOpen: boolean, worker: Partial<Worker>) => void,
}

export type WorkerActionsPropsType = {
  worker: Partial<Worker>;
  setIsEmploymentsModalOpen: (isOpen: boolean, workerId: string) => void;
  setIsLinkedAccountsModalOpen: (isOpen: boolean, workerId: string) => void;
  setIsShiftsModalOpen: (isOpen: boolean, workerId: string) => void;
  setUpdateWorkerDetailsOpen: (isOpen: boolean, worker: Partial<Worker>) => void,
}

export enum SelectedField {
  Email = 'email',
  // Phone = 'phone',
  // Name = 'name',
  None = ''
}

export type WorkersDetailsUpdateValuesType = {
  selectedField: SelectedField,
  email: string,
  otp: string[]
}

export type VerificationCodeTypeRequest = {
  email?: string,
  mobile?: {
    countryCode: string,
    phoneNumber: string,
  }
}

export type RequestVerificationCodeParamsType = {
  workerID: WorkerIDType,
  data: VerificationCodeTypeRequest
}

export type UpdateWorkerEmailValuesType = {
  email: string,
  otpCode: string
}

export type UpdateWorkerEmailParamsType = {
  workerID: WorkerIDType,
  data: UpdateWorkerEmailValuesType
}
