import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import {
  BatchIDType,
  BatchItem,
} from 'types/BatchTypes';
import PriceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import AppChip from 'components/AppChip/AppChip';
import HighLightSearch from 'components/HighLightSearch';
import Access, { AccessUnit } from 'components/Access/Access';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  batchSelector,
  fetchBatch,
} from 'store/slices/batches';
import {
  useEffect,
  useState,
} from 'react';
import DownloadFile from 'utils/downloadFile';
import {
  listStyles,
  listItemStyles,
  customerListItemNameAndStatusesStyles,
  statusBadgeStyles,
  detailsStyles,
  listItemInfoStyles,
  customerListItemTitleStyles,
  statusAndMenuStyle,
  exportIconStyle,
} from '../../../BatchesHelpers';

export type BatchesListItemPropsType = {
  batch: BatchItem,
  search?: string
}

const TPOBatchesListItem = ({
  batch,
  search,
}: BatchesListItemPropsType) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'batches', 'employees', 'dateFormats', 'payGroups']);
  const [css] = useStyletron();
  const [selectedBatch, setSelectedBatch] = useState<BatchIDType>(undefined);
  const batchState = useAppSelector(batchSelector);
  const { csv, id } = batchState || {};

  const dateFormat = t('dateFormats:standard');

  const handleMenuItemClick = ({
    batchID,
  }: {
    batchID: BatchIDType,
  }) => {
    dispatch(fetchBatch({ batchID }));
    setSelectedBatch(batchID);
  };

  useEffect(() => {
    if (id === selectedBatch && csv) {
      DownloadFile(
        `${selectedBatch}.csv`,
        `data:text/csv;charset=UTF-8,${encodeURIComponent(csv)}`,
      );
      setSelectedBatch(undefined);
    }
  }, [id, selectedBatch]);

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(customerListItemNameAndStatusesStyles)}>
          <div className={css(customerListItemTitleStyles)}>
            <HighLightSearch search={search}>
              {batch?.batchReference}
            </HighLightSearch>
          </div>

          <div className={css(statusAndMenuStyle)}>
            <span className={css({
              ...statusBadgeStyles(batch.status),
              borderRadius: '4px',
              marginRight: '8px',
            })}
            >
              <HighLightSearch search={search}>
                {t(`batches:statuses.${batch?.status}`)}
              </HighLightSearch>
            </span>
            <Access
              oneOf={[AccessUnit.EWAClientManager, AccessUnit.EWAManager]}
            >
              <FontAwesomeIcon
                title={t('batches:exportToCSV')}
                size="lg"
                className={css(exportIconStyle)}
                icon={faFileCsv}
                onClick={() => handleMenuItemClick({ batchID: batch?.id.toString() })}
              />
            </Access>
          </div>
        </div>

        <div className={css(listItemInfoStyles)}>
          <div>
            <span className={css(detailsStyles)}>
              {t('common:payPeriod')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {`${moment(batch?.payrollPeriodStartDate).format(dateFormat)} - ${moment(batch?.payrollPeriodEndDate).format(dateFormat)}`}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('payGroups:payGroupsCycle')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.payGroupPayFrequencyRule}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('batches:creationDate.label')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {moment(batch?.creationDate).format(dateFormat)}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('employees:workers')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.workersCount}
                  </HighLightSearch>
                )}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('batches:amount')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.amount && PriceFormatter().format(unScalePrice(batch?.amount.value, batch?.amount.scale))}
                  </HighLightSearch>
                )}
              />
            </span>
            <span className={css(detailsStyles)}>
              {t('batches:generated')}
              :
              <AppChip
                items={(
                  <HighLightSearch search={search}>
                    {batch?.creationTrigger?.replace('ADMIN_API', t('batches:manuallyGenerated')?.replace('SCHEDULER', t('batches:autoGenerated')))}
                  </HighLightSearch>
                )}
              />
            </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default TPOBatchesListItem;
