import React, {
  createContext, useEffect, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  FormikHandlers, FormikHelpers, FormikState, useFormik,
} from 'formik';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import {
  SelectedField,
  Worker,
  WorkersDetailsUpdateValuesType,
} from 'types/WorkerTypes';
import { Layer } from 'baseui/layer';
import WorkersDetailsUpdateModalInitialValues from 'initialValues/WorkersInitialValues';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import AppModal from 'components/AppModal/AppModal';
import Loader from 'components/Loader';
import AppInput from 'components/Form/AppInput';
import AppSelect from 'components/Form/AppSelect';
import { LabelSmall, ParagraphSmall } from 'baseui/typography';
import { Block } from 'baseui/block';
import updateWorkerDetailsSchemaValidationSchema from 'validation/updateWorkerDetailsSchema';
import { Button, KIND, SIZE } from 'baseui/button';
import verficationCodeDataMapper from 'dataMappers/workersDataMapper';
import { emptyPlaceholder } from 'theme';
import {
  requestVerificationCode, searchWorkers, updateWorkerEmail, workersFilterSelector,
} from 'store/slices/workers';
import AppPinCode from 'components/Form/AppPinCode';
import { NotificationType, setNotification } from 'store/slices/notification';

export const WorkersDetailsUpdateContext = createContext(
  {} as FormikState<WorkersDetailsUpdateValuesType> & FormikHelpers<WorkersDetailsUpdateValuesType> & FormikHandlers,
);

interface Props {
  worker: Partial<Worker>;
}

const WorkersDetailsUpdateModal = ({ worker }: Props) => {
  const { t } = useTranslation(['workers', 'common']);
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const isModalOpen = checkIsModalOpen(modals, ModalNames.WORKERS_DETAILS_UPDATE_MODAL);
  const [isCodeButtonDisabled, setIsCodeButtonDisabled] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [codeTimer, setCodeTimer] = useState<string>('');
  const [otpCodeError, setOtpCodeError] = useState<boolean>(false);
  const timerIntervalRef = useRef<number | null>(null);
  const workersSearch = useAppSelector(workersFilterSelector);
  const [pending, setPending] = useState<boolean>(false);

  const selectedFieldOptions = Object.values(SelectedField).filter((value: SelectedField) => value !== SelectedField.None).map((value) => ({
    value,
    label: t(`common:${value}`),
  }));

  const sendVerificationCode = (selectedField: SelectedField, formValues: WorkersDetailsUpdateValuesType) => {
    if (worker) {
      setIsCodeButtonDisabled(true);
      setOtpCodeError(false);
      const verificationCodeData = verficationCodeDataMapper(selectedField, formValues);
      dispatch(requestVerificationCode({
        workerID: worker.id,
        data: verificationCodeData,
      }))
        .unwrap()
        .then(() => {
          setCodeSent(true);
          let timeLeft = 120;
          timerIntervalRef.current = window.setInterval(() => {
            timeLeft -= 1;
            const minutes = Math.floor(timeLeft / 60);
            const seconds = timeLeft % 60;
            setCodeTimer(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
            if (timeLeft <= 0) {
              clearInterval(timerIntervalRef.current!);
              setIsCodeButtonDisabled(false);
              setCodeTimer('');
            }
          }, 1000);
        })
        .catch(() => {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:somethingWentWrong'),
            autoHideDuration: 3000,
          }));
          setCodeSent(false);
          setCodeTimer('');
          setIsCodeButtonDisabled(false);
        });
    }
  };

  const initialValues = {
    ...WorkersDetailsUpdateModalInitialValues,
  };

  const validationSchema = updateWorkerDetailsSchemaValidationSchema(worker.email);

  const fetchWorkersAfterFieldUpdate = () => {
    const filter: any = { pageSize: 999 };
    if (workersSearch.firstName !== '' || workersSearch.lastName !== '' || workersSearch.email !== '' || workersSearch.workerId !== '') {
      dispatch(searchWorkers({
        filter: {
          ...filter,
          firstName: workersSearch.firstName.trim() || '',
          lastName: workersSearch.lastName.trim() || '',
          email: workersSearch.email || '',
          workerId: workersSearch.workerId || '',
        },
      }));
    }
  };

  const onSubmit = (
    values: WorkersDetailsUpdateValuesType,
  ) => {
    if (worker) {
      const otpCode = values.otp && values.otp.join('');
      setPending(true);
      dispatch(updateWorkerEmail({
        workerID: worker.id,
        data: {
          email: values.email,
          otpCode,
        },
      }))
        .unwrap()
        .then(() => {
          dispatch(setNotification({
            type: NotificationType.SUCCESS,
            isOpen: true,
            titleKey: t('notifications:successfullyUpdatedWorkerDetails'),
            autoHideDuration: 3000,
            multipleLinesTitle: true,
          }));
          setPending(false);
          dispatch(setModal({
            name: ModalNames.WORKERS_DETAILS_UPDATE_MODAL,
            isOpen: false,
          }));
          fetchWorkersAfterFieldUpdate();
        })
        .catch((err) => {
          dispatch(setNotification({
            type: NotificationType.ERROR,
            isOpen: true,
            titleKey: t('errors:somethingWentWrong'),
            text: err.status === 400 ? t('errors:errorOtpCodeIsNotValid') : t('errors:errorUnableToUpdateWorkerDetails'),
            autoHideDuration: 3000,
          }));
          setPending(false);
          setOtpCodeError(true);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
    setFieldValue,
  } = formik;

  const handleModalClose = () => {
    resetForm();
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      setCodeTimer('');
    }
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      resetForm();
      setValues({
        ...values,
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isSubmitting && otpCodeError) {
      setSubmitting(false);
      setFieldValue('otp', initialValues.otp);
    }
  }, [isSubmitting, otpCodeError]);

  useEffect(() => () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
    }
  }, []);

  const validateOtp = (otp: string[]): boolean => {
    if (otp.length !== 4) {
      return false;
    }

    return otp.every((value) => typeof value === 'string' && value.trim() !== '');
  };

  return (
    <Layer index={400}>
      {worker
        && (
        <WorkersDetailsUpdateContext.Provider value={formik}>
          <form>
            <AppModal
              modal={ModalNames.WORKERS_DETAILS_UPDATE_MODAL}
              title={t('workers:workersDetailsUpdateModal.title')}
              cancelBtnText={t('common:cancel')}
              onClose={handleModalClose}
              actionBtnText={t('common:update')}
              onAction={handleSubmitExtended}
              isCloseDisabled={isSubmitting}
              isActionDisabled={isSubmitting || !isValid || !codeSent || !validateOtp(values.otp)}
              minWidth="360px"
              maxWidth="100%"
              modalWidth={['360px', '360px', '500px', '500px']}
            >
              <Loader active={pending || (otpCodeError && isSubmitting)} />
              <Grid
                align={ALIGNMENT.center}
                gridColumns={12}
                gridMargins={17}
              >
                <Cell
                  span={[12, 12, 12]}
                  align={ALIGNMENT.start}
                >
                  <LabelSmall display="inline-flex">
                    {t('common:name')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex" margin="8px 0 8px 0">
                    {`${worker.firstName} ${worker.lastName}` || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
                <Cell
                  span={[12, 12, 12]}
                  align={ALIGNMENT.start}
                >
                  <LabelSmall display="inline-flex">
                    {t('common:email')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex" margin="8px 0 8px 0">
                    {worker.email || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
                <Cell
                  span={[12, 12, 12]}
                  align={ALIGNMENT.start}
                >
                  <LabelSmall display="inline-flex">
                    {t('common:phone')}
                    : &nbsp;
                  </LabelSmall>
                  <ParagraphSmall display="inline-flex" margin="8px 0 8px 0">
                    {worker.mobilePhone || emptyPlaceholder}
                  </ParagraphSmall>
                </Cell>
              </Grid>
              <Grid
                align={ALIGNMENT.start}
                gridColumns={12}
                gridMargins={12}
              >
                <Block
                  width="100%"
                  margin="0 -12px"
                >
                  <Cell
                    align={ALIGNMENT.start}
                    span={12}
                  >
                    <AppSelect
                      name="selectedField"
                      label={t('workers:workersDetailsUpdateModal.fieldType')}
                      cellSpan={[12, 12, 12]}
                      clearable={false}
                      options={selectedFieldOptions}
                      context={WorkersDetailsUpdateContext}
                      selectDisabled={codeSent}
                      selectProps={{
                        overrides: {
                          ControlContainer: {
                            props: {
                              'data-testid': 'control-container',
                            },
                          },
                          Input: {
                            props: {
                              id: 'WorkersDetailsUpdateModal-select-field',
                              'data-testid': 'WorkersDetailsUpdateModal-select-field',
                              autoComplete: 'off',
                            },
                          },
                        },
                      }}
                    />
                  </Cell>
                </Block>
              </Grid>
              { values.selectedField
              && (
                <Grid
                  align={ALIGNMENT.start}
                  gridColumns={12}
                  gridMargins={12}
                  gridGaps={6}
                >
                  <Block
                    width="100%"
                    margin="0 -12px"
                  >
                    <Cell span={12}>
                      <AppInput
                        name="email"
                        cellSpan={[12, 12, 12]}
                        label={t('workers:workersDetailsUpdateModal.newEmail')}
                        context={WorkersDetailsUpdateContext}
                        inputProps={{
                          autoComplete: 'off',
                          disabled: codeSent,
                        }}
                      />
                      {values.selectedField
                        && values.email
                        && !formik.errors.email && (
                          <Block>
                            <Cell
                              span={[12, 12, 12]}
                              align={ALIGNMENT.start}
                            >
                              <ParagraphSmall display="inline-flex" margin="8px 0 8px 0">
                                {t('workers:workersDetailsUpdateModal.verificationCodeWillBeSentToPhone', { contactMethod: worker.mobilePhone })}
                              </ParagraphSmall>
                            </Cell>
                            <Cell
                              span={[12, 12, 12]}
                              align={ALIGNMENT.start}
                            >
                              <Block
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                gridGap="8px"
                              >
                                <Button
                                  kind={KIND.primary}
                                  size={SIZE.compact}
                                  disabled={isCodeButtonDisabled}
                                  isLoading={isCodeButtonDisabled && !codeSent}
                                  onClick={() => {
                                    const { value }: any = values.selectedField[0];
                                    sendVerificationCode(value, values);
                                  }}
                                >
                                  {codeSent
                                    ? t('workers:workersDetailsUpdateModal.resendCode')
                                    : t('workers:workersDetailsUpdateModal.sendCode')}
                                </Button>
                                {codeTimer !== '' && codeSent && (
                                <ParagraphSmall display="inline-flex" data-testid="code-timer">
                                  {codeTimer}
                                </ParagraphSmall>
                                )}
                              </Block>
                            </Cell>
                          </Block>
                      )}
                      {codeSent && (
                        <AppPinCode
                          name="otp"
                          context={WorkersDetailsUpdateContext}
                          cellSpan={12}
                          cellAlign={ALIGNMENT.start}
                          showStar
                          placeholder=""
                          disabled={isSubmitting}
                        />
                      )}
                    </Cell>
                  </Block>
                </Grid>
              )}
            </AppModal>
          </form>
        </WorkersDetailsUpdateContext.Provider>
        )}
    </Layer>
  );
};

export default WorkersDetailsUpdateModal;
