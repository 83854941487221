import { memo, ReactNode } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import hasAccess from 'utils/hasAccess';
import { AccessCheckType, AccessUnit } from './Access/Access';

export interface PrivateRouteProps extends RouteProps {
  hasToken: boolean
  children: ReactNode
  all?: AccessUnit[]
  oneOf?: AccessUnit[]
  not?: boolean
  combination?: AccessUnit[][]
}

const checkCombinationAccess = (combination?: AccessUnit[][]) => {
  if (!combination) return true;

  // Ensure the user has *all* roles from at least one of the provided combinations
  return combination.some((combo) => hasAccess(AccessCheckType.all, combo));
};

const PrivateRoute = ({
  hasToken,
  children,
  all,
  oneOf,
  not,
  combination,
  ...rest
}: PrivateRouteProps) => {
  const hasAccessProvided = !!(all || oneOf || combination);

  let handleAccess = true;

  if (all) handleAccess = hasAccess(AccessCheckType.all, all);
  if (oneOf) handleAccess = handleAccess && hasAccess(AccessCheckType.oneOf, oneOf);
  if (combination) handleAccess = handleAccess && checkCombinationAccess(combination);

  if (not) {
    handleAccess = !handleAccess;
  }

  return (
    <Route {...rest}>
      {
        hasToken && (!hasAccessProvided || handleAccess)
          ? children
          : <Redirect to="/login" />
      }
    </Route>
  );
};

export default memo(PrivateRoute);
